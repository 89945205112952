import React, { useCallback, useEffect, useRef } from 'react';
import customPipelineModule from './customPipelineModule';
import { Canvas } from '@react-three/fiber';
import ARScene from '../ArScene';
import * as THREE from 'three';
import customThreejsPipelineModule from './customThreejsPipelineModule';
import TouchContainer from '../TouchContainer';

import { LoadingFactory } from './loading-module/loading-module';
import { AlmostThereFactory } from './almost-there-module/almost-there-module';
import TapScreenPrompt from '../TapScreenPrompt';
import { useXrStore } from '../../../services/xrService';

import { MediaRecorder } from '../MediaRecorder/mediarecorder';

import { CostaCoffeeLogoWhiteElement } from './styles';
import LoadingScreen from './loading-module/loadingScreen';
import { useUIStore } from '../../../services/uiService';
import { useModelStore } from '../../../services/modelService';

declare let XR8: any;

const Loading = LoadingFactory();
const AlmostThere = AlmostThereFactory();

const svg2Img = svgElement => {
  const svgURL = new XMLSerializer().serializeToString(svgElement);
  const img = new Image();
  img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
  return img;
};

(window as any).THREE = THREE;

export default function XRCanvas() {
  const mainCanvas = useRef();

  // @ts-ignore
  const { setXrLoaded } = useXrStore();

  // @ts-ignore
  const character = useModelStore(state => state.character);

  // @ts-ignore
  const characterIsSet = useModelStore(state => state.characterIsSet);

  // @ts-ignore
  const { previewPhotoTaken } = useUIStore();

  const onxrloaded = useCallback(() => {
    const customThree = customThreejsPipelineModule();

    MediaRecorder.initRecordButton(); // Adds record button
    MediaRecorder.initMediaPreview(); // Adds media preview and share
    XR8.CanvasScreenshot.configure({ maxDimension: 1280, jpgCompression: 100 });

    const waterMark = svg2Img(document.querySelector('#CostaCoffeeLogo'));

    MediaRecorder.configure({
      watermarkImageUrl: waterMark.src,
      watermarkMaxWidth: 85,
      watermarkMaxHeight: 5,
      watermarkLocation: 'topMiddle',
    }); // Adds media preview and share

    XR8.addCameraPipelineModules([
      // Existing pipeline modules.
      XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      customThree, // Rendering Pipeline Module
      XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      XR8.CanvasScreenshot.pipelineModule(), // Required for photo capture
      AlmostThere.pipelineModule(character), // Detects unsupported browsers and gives hints.
      Loading.pipelineModule(), // Manages the loading screen on startup.
      // XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      customPipelineModule(customThree), // Custom pipeline modules.
    ]);

    if (XR8.XrDevice.isDeviceBrowserCompatible()) {
      setXrLoaded();
    }

    XR8.XrController.configure({
      enableLighting: false,
      enableWorldPoints: true,
      disableWorldTracking: false,
    });

    XR8.run({ canvas: mainCanvas.current });
  }, [setXrLoaded, character]);

  useEffect(() => {
    if (!characterIsSet) {
      return;
    }

    Loading.showLoading({ onxrloaded });

    return () => {
      XR8.stop();
      XR8.clearCameraPipelineModules();
    };
  }, [onxrloaded, characterIsSet]);

  return (
    <React.Fragment>
      <TouchContainer>
        <CostaCoffeeLogoWhiteElement id="CostaCoffeeLogo" previewOn={!previewPhotoTaken} />
        <Canvas
          ref={mainCanvas}
          onContextMenu={e => {
            e.nativeEvent.preventDefault();
          }}
          style={{ width: '100%', height: '100%' }}
        >
          <ARScene />
        </Canvas>
        <TapScreenPrompt />
      </TouchContainer>
      <LoadingScreen />
    </React.Fragment>
  );
}
