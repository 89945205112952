import React, { useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import { TextureLoader } from 'three';
import { AdditiveBlending, Vector3, ClampToEdgeWrapping } from 'three';
import particleVert from './shader/particles.vert.js';
import particleFrag from './shader/particles.frag.js';
import { useXrStore } from '../../../services/xrService';
import { useModelStore } from '../../../services/modelService';
import { useLoader } from '@react-three/fiber';
import star from './star.png';

const LOOP_FOREVER = false;
const PARTICLE_COUNT = 16384; // 4096 65536

const camDir = new Vector3();

export default function ParticleAnimation() {
  const materialRef = useRef();

  const { isPlaced, isShown, show, animationFinished, setAnimationFinished } = useXrStore();
  const character = useModelStore(state => state.getCharacter());
  const clock = useThree(state => state.clock);
  const startTime = useRef(0);

  const starTexture = useLoader(TextureLoader, star);
  starTexture.wrapS = starTexture.wrapT = ClampToEdgeWrapping;

  const { idArray, indexArray, sideArray, starProbabilityArray, sizeArray, rotateSpeedArray } = useMemo(
    () => ({
      idArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map((v, index) => index / PARTICLE_COUNT)),
      indexArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map((v, index) => index)),
      sideArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map((v, index) => index % 2)),
      starProbabilityArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map(() => Math.random())),
      sizeArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map(() => 0.1 * (0.5 + 0.9 * Math.random()))),
      rotateSpeedArray: Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map(() => 2 * (2 * Math.random() - 1))),
    }),
    []
  );

  const uniforms = useMemo(
    () => ({
      uCameraAngle: { value: 0.0 },
      uTime: { value: 0.0 },

      uSpinDuration: { value: 2.5 },
      uContractDuration: { value: 1.7 },
      uExplodeDuration: { value: 3 },

      uFadeInIdOffset: { value: 2 },
      uFadeInDuration: { value: 1 },
      uFadeOutDuration: { value: 3 },

      uStarTexture: { value: starTexture },
    }),
    [starTexture]
  );

  const visible = isPlaced && !animationFinished;

  useFrame(context => {
    if (!visible) return;

    const time = context.clock.getElapsedTime() - startTime.current;
    const totalTime = uniforms.uSpinDuration.value + uniforms.uContractDuration.value + uniforms.uExplodeDuration.value;
    const showTime = uniforms.uSpinDuration.value + uniforms.uContractDuration.value;

    if (time > showTime && !isShown) {
      show();
    }

    if (!LOOP_FOREVER) {
      if (time > totalTime && !animationFinished) {
        setAnimationFinished();
        return;
      }
    }

    context.camera.getWorldDirection(camDir);
    materialRef.current.uniforms.uCameraAngle.value = Math.atan2(camDir.z, camDir.x);
    materialRef.current.uniforms.uTime.value = LOOP_FOREVER ? time % totalTime : Math.min(time, totalTime);
    materialRef.current.needsUpdate = true;
  }, 1);

  useEffect(() => {
    if (isPlaced) {
      startTime.current = clock.getElapsedTime();
    }
  }, [isPlaced, clock]);

  if (!visible) {
    return null;
  }

  return (
    <group rotation={[0.0, 0, 0.0]} dispose={null}>
      <instancedMesh
        args={[null, null, PARTICLE_COUNT]}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={character.introScale}
      >
        <planeBufferGeometry attach="geometry" args={[1, 1]}>
          <instancedBufferAttribute attachObject={['attributes', 'id']} args={[idArray, 1, false]} />
          <instancedBufferAttribute attachObject={['attributes', 'index']} args={[indexArray, 1, false]} />
          <instancedBufferAttribute attachObject={['attributes', 'side']} args={[sideArray, 1, false]} />
          <instancedBufferAttribute attachObject={['attributes', 'size']} args={[sizeArray, 1, false]} />
          <instancedBufferAttribute attachObject={['attributes', 'rotateSpeed']} args={[rotateSpeedArray, 1, false]} />
          <instancedBufferAttribute
            attachObject={['attributes', 'starProbability']}
            args={[starProbabilityArray, 1, false]}
          />
        </planeBufferGeometry>
        <shaderMaterial
          ref={materialRef}
          blending={AdditiveBlending}
          uniforms={uniforms}
          vertexShader={particleVert}
          fragmentShader={particleFrag}
          depthTest={true}
          depthWrite={false}
          transparent={true}
          attach="material"
        />
      </instancedMesh>
    </group>
  );
}
