import styled from 'styled-components';

export const TouchArea = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  canvas {
    top: 0 !important;
  }
`;
