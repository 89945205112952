import { useRef,useState} from 'react';
import Slider from 'react-slick';
import { AuxSpace, StyledBackBtnIcon } from '../../components/threeAR/MediaRecorder/MediaPreview/styles';
import carouselData from '../../utils/carousel-data';
import { useHistory, useParams } from 'react-router-dom';
import { useModelStore } from '../../services/modelService';
import { 
  SliderItem,
  SliderItemWrapper,
  SliderItemTextWrapper,
  SliderItemImageWrapper,
  SliderContainer,
  Container,
  StyledButtonRed,
  ActionBar,
} from './styles'

const ShareCarousel: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  // @ts-ignore
  const { findSelectedCharacter } = useModelStore();
  const character = findSelectedCharacter(useParams<{character: string}>());

  // const history = useHistory();

  const handleCloseClick = () => {
    // history.push(`/ar/${character}`);
    window.location.href = window.location.origin + `/ar/${character}`;
  };

  const beforeSlideChange = (oldIndex, newIndex) => {
    setActiveSlide(newIndex);
  }

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    buttons: false,
    beforeChange: beforeSlideChange,
    touchThreshold: 100,
  };

  const renderSliderItems = () =>
    carouselData.map(item => (
      <SliderItemWrapper key={item.name}>
        <SliderItem>
          <SliderItemTextWrapper>
            <img src={item.text} alt={`text-${item.name}`} />
          </SliderItemTextWrapper>
          <SliderItemImageWrapper>
            <img src={item.src} alt={`img-${item.name}`} />
          </SliderItemImageWrapper>
        </SliderItem>
      </SliderItemWrapper>
    ));

  async function shareImage() {
    // const response = await fetch(carouselData[activeSlide].share);
    // const blob = await response.blob();
    // const filesArray = [
    //   new File(
    //     [blob],
    //     'costacoffeefestive.jpg',
    //     {
    //       type: "image/jpeg",
    //       lastModified: new Date().getTime()
    //     }
    //  )
    // ];

    // @ts-ignore
    // if(navigator.canShare && navigator.canShare({ files: filesArray })) {
    //   navigator.share({ files: filesArray } as ShareData);
    // }  else {
    navigator.share({ url: carouselData[activeSlide].url } as ShareData);
    // }
  }

  return (
    <Container>
      <SliderContainer>
        <Slider {...settings}>{renderSliderItems()}</Slider>
      </SliderContainer>
      <ActionBar>
        <StyledBackBtnIcon onClick={handleCloseClick} />
        <StyledButtonRed onClick={() => shareImage()} />
        <AuxSpace></AuxSpace>
      </ActionBar>
    </Container>
  );
};

export default ShareCarousel;
