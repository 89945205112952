import { useEffect, useRef, useState } from 'react';
import { Color, LinearEncoding, LoopOnce, LoopRepeat } from 'three';
import { useXrStore } from '../../../services/xrService';
import { useModelStore } from '../../../services/modelService';
import { useAnimations, useGLTF } from '@react-three/drei';
import { useSpring } from 'react-spring';
import { useFrame } from '@react-three/fiber';
import { useUIStore } from '../../../services/uiService';

export default function AnimatedModel() {
  const group = useRef();

  const character = useModelStore(state => state.getCharacter());

  const { nodes, materials, animations } = useGLTF(character.src);
  const { mixer, actions } = useAnimations(animations, group);
  const { isShown, isPlaced, setAnimationFirstTimePlayed, animationFirstTimePlayed } = useXrStore();
  const { isAbleToPositionCharacter, setIsAbleToPositionCharacter} = useUIStore()

  const characterComponent = character.component(nodes, materials, isShown, group);

  const [{ alpha }, setIntro] = useSpring(() => ({
    alpha: 0.0,
    config: {
      duration: 500,
      easing: t => t,
    },
  }));

  const [{ emissive }, setEmissive] = useSpring(() => ({
    emissive: 1.0,
    delay: 500,
    config: {
      duration: 700,
      easing: t => t,
    },
  }));

  const setWeight = (action, weight) => {
    action.enabled = true;
    action.setEffectiveTimeScale(0.5);
    action.setEffectiveWeight(weight);
  };

  const playAnimation = animation => {
    Object.entries(actions).forEach(([key, value]) => {
      if (key === animation) {
        value.reset();
        setWeight(value, 1);
        value.fadeIn(2);
        value.play();
      } else {
        value.fadeOut(2);
      }
    });
  };

  useEffect(() => {
    actions.idle.loop = LoopRepeat;
    actions.signature.loop = LoopOnce;
    actions.signature.clampWhenFinished = true;
    actions.intro.loop = LoopOnce;
    actions.intro.clampWhenFinished = true;

    setWeight(actions.idle, 1.0);
    setWeight(actions.intro, 1.0);
    setWeight(actions.signature, 1.0);

    playAnimation('idle');

    const onFinish = () => {
      playAnimation('idle');
      setIsAbleToPositionCharacter(false);
    };

    mixer.addEventListener('finished', onFinish);
    return () => {
      mixer.removeEventListener('finished', onFinish);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, mixer]);

  useEffect(() => {
    for (const key in materials) {
      materials[key].transparent = true;
      if (materials[key].map) {
        materials[key].map.encoding = LinearEncoding;
      }
    }
  }, [materials]);

  useEffect(() => {
    if (isShown) {
      setIsAbleToPositionCharacter(false);
      setIntro.start({ alpha: 1.0 });
      setEmissive.start({ emissive: 0.0 });
      playAnimation('intro');
    }
    // eslint-disable-next-line
  }, [isShown, setIntro, setEmissive]);

  useFrame((context, delta) => {
    mixer.update(delta);

    for (const key in materials) {
      materials[key].opacity = alpha.get();
      materials[key].emissive = new Color(0xefefff);
      materials[key].emissiveIntensity = emissive.get();
    }
  }, 0);

  const startSignatureAnimation = () => {
    if (isPlaced && !isAbleToPositionCharacter) {
      playAnimation('signature');
      setIsAbleToPositionCharacter(true);
    }
    if (!animationFirstTimePlayed) setAnimationFirstTimePlayed();
  };

  return (
    <group dispose={null} rotation={[-Math.PI * 0.5, 0, 0]} position={character.offset}>
      {isPlaced && (
        <mesh name="hitbox" renderOrder={10000} position={[0, -0.2, 1]} onClick={startSignatureAnimation}>
          <boxBufferGeometry args={[0.75, 0.75, 1]} />
          <meshBasicMaterial transparent={true} opacity={0} />
        </mesh>
      )}
      {characterComponent}
    </group>
  );
}
