import { useState } from 'react';
import styled from 'styled-components'
import { theme } from "../../globalStyles";
import { motion } from "framer-motion"
import Btn from '../button';
import { AnimatePresence } from "framer-motion"
import { useTranslation, Trans } from "react-i18next";
import { useUIStore } from '../../services/uiService';
import { useVersionStore } from '../../services/versionService';
import i18next from 'i18next';

const CookieBanner: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { setAcceptedCookiBanner,acceptedCookieBanner } = useUIStore();

  const isBannerVisible = useVersionStore(state => state.isBannerVisible());

  const [accepted, setAccepted] = useState(!(acceptedCookieBanner === 'false'));

  const acceptClickEvent = () => {
    setAccepted(true);
    setAcceptedCookiBanner();
    
    // The Google Tag Manager
    // @ts-ignore
    if("__GlobalEnableGTM" in window && typeof window.__GlobalEnableGTM !== "undefined") {
      // @ts-ignore
      window.__GlobalEnableGTM();
    }
  }
  /**
   * This event will be triggered if people just click the OK button,
   * which appears for people who do not need to accept cookies, but
   * need to accept that "older devices might struggle" text.
   */
  const okClickEvent = () => {
    setAccepted(true);
    setAcceptedCookiBanner();
  }

  const declineClickEvent = () => {
    setAccepted(true);
  }

  return (
    <AnimatePresence>
      {!accepted && (
        <CookieBannerContainer 
          exit={{
            bottom: -300, 
            opacity: 0, 
            transition: { duration: .5 }
          }}
        >
          <TxtContainer lang={i18next.language}>
            { isBannerVisible && <Trans
              i18nKey="cookie_banner"
              components={{ anchor: <a /> }}/> }

            {t('device_warning')}
          </TxtContainer>

          <TwoButtonContainer>
            { isBannerVisible && <Btn copy={t('decline_btn')} clickEvent={declineClickEvent}/> }
            { isBannerVisible && <Btn primary copy={t('accept_btn')} clickEvent={acceptClickEvent}/>}
            { !isBannerVisible && <Btn primary copy={t('ok_btn')} clickEvent={okClickEvent}/>}
          </TwoButtonContainer>

        </CookieBannerContainer>
      )}
    </AnimatePresence>
  );
}

const CookieBannerContainer = styled(motion.div)`
  background-color: ${theme.colors.costaRed};
  border-radius:28px 28px 0px 0px;
  bottom: 0;
  padding: 32px 16px;
  position: absolute;
  text-align: center;
  width: calc(100% - 32px);
  z-index: 10000000;
`

const TxtContainer = styled.p`
  font-family: ${theme.fonts.gothamBook};
  color: ${theme.colors.costaOffWhite};
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  padding-left: 3px;
  text-align: ${props => (props.lang === 'ar' ? "right" : "left")};

  a {
    font-family: ${theme.fonts.gothamMedium};
    color: ${theme.colors.costaOffWhite};
  }
`

const TwoButtonContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-content: flex-start; 
`

export default CookieBanner;