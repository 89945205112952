import styled from 'styled-components';
import { NotFoundHomeButton } from '../../utils/svg';
import { Link } from 'react-router-dom';
import { theme } from '../../globalStyles';

export const NotFoundContainer = styled.div`

  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;

  .logoRed {
    padding-top: 2rem;
    flex-shrink: 0;
    width: 124px;
    height: 47px;
  }
`;

export const ButtonContainer = styled.div`

  display: flex;
  width: 100%;
  margin-bottom: 18px;
  gap: 16px;
  justify-content: center;
  z-index: 20;
`;

export const CenterContainer = styled.div`
  
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledNotFound404Text = styled.h1`

  y: ${theme.fonts.brandomGrotesqueBold};
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  line-height: 120px;
  text-align: center;
  color: #6D1F37;
  margin: 0; 
  margin-bottom: 20px;
`;

export const StyledNotFoundOopsText = styled.p`

  font-family: ${theme.fonts.brandomGrotesqueBold};
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 57px;
  text-align: center;
  color: #6D1F37;
  margin: 0;
  margin-bottom: 5px;
`;

export const StyledNotFoundWrongText = styled.p`

  font-family: ${theme.fonts.gothamBold};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6D1F37;
  margin: 0;
`;

export const Home = styled(Link)`
  display: block;
`;

export const StyledNotFoundHomeButton = styled(NotFoundHomeButton)`
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
`;
