import create from '../../utils/zustand/create';
import { Quaternion } from 'three';

export const [useXrStore, xrApi] = create(module, (set, get) => ({
  camera: null,
  scene: null,
  surface: null,
  lookAt: [0, 0, 0],
  isPlaced: false,
  isShown: false,
  position: [0, 0, 0],
  shareDialogOpen: false,
  rotation: new Quaternion(),
  animationFinished: false,
  xrLoaded: false,
  animationFirstTimePlayed: false,

  setAnimationFirstTimePlayed: () => {
    set({ animationFirstTimePlayed: true });
  },

  setXrLoaded: () => {
    set({ xrLoaded: true });
  },

  setAnimationFinished: () => {
    set({ animationFinished: true });
  },

  setSurface: surface => {
    set({ surface });
  },

  place: (position) => {
    const cameraPos = get().camera.position;
    const lookAt = [cameraPos.x, 0, cameraPos.z]

    set({
      isPlaced: true,
      position,
      lookAt,
    });
  },

  show: () => {
    set({ isShown: true });
  },

  showShareDialog: show => {
    set({ shareDialogOpen: show });
  },
}));
