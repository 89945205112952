import React, { useEffect, useMemo, useState } from 'react';
import { PromptContainer, PromptContainerGlobal, StyledTapOnScreenIcon, TapText } from './styles';
import { useXrStore } from '../../../services/xrService';
import { useVersionStore } from '../../../services/versionService';
import { useModelStore } from '../../../services/modelService';
import { TapOnSurfaceGlobalStyled, TapOnCharacterGlobalStyled } from '../../../views/Intro/styles';

export default function TapScreenPrompt() {
  const { xrLoaded, isPlaced, animationFinished, animationFirstTimePlayed } = useXrStore();
  const character = useModelStore(state => state.getCharacter());
  const [showTapCharacterContent, setShowTapCharacterContent] = useState(false);
  // @ts-ignore
  const { isGlobalVersion } = useVersionStore();
  
/*   const [tapCanvasContent, tapCharacterContent] = useMemo(() => { */
    
    const tapCanvasContent = (
      <React.Fragment>
        
        {isGlobalVersion() && ( // GLOBAL
          <TapOnSurfaceGlobalStyled /> // SVG Tap Icon 
        )}

        {!isGlobalVersion() && ( // EN
          <TapText>
            Find an even surface
            <br />
            and tap to bring {character.uppercaseName} to life!
          </TapText>
        )}

      </React.Fragment>
    );
    const tapCharacterContent = (
      <React.Fragment>

        {isGlobalVersion() && ( // GLOBAL
            <TapOnCharacterGlobalStyled />
        )}

        {!isGlobalVersion() && ( // EN
          <>
            <StyledTapOnScreenIcon />
            <TapText>Now give the character a tap</TapText>
          </>
        )}

      </React.Fragment>
    );

   /*  return [tapCanvasContent, tapCharacterContent]; */
    
/*   }, [character]); */

  const showTapCanvasContent = !isPlaced;

  useEffect(() => {
    if (animationFinished && !animationFirstTimePlayed) {
      const timer = setTimeout(() => {
        setShowTapCharacterContent(true);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [animationFinished, animationFirstTimePlayed]);

  // animationFinished && !animationFirstTimePlayed;
  const showTapContainer = xrLoaded && !animationFirstTimePlayed && (showTapCanvasContent || showTapCharacterContent);

  return (
    <React.Fragment>
      {isGlobalVersion() && showTapContainer && (
        <PromptContainerGlobal>
          <div id="tapBox">
            {showTapCanvasContent && tapCanvasContent}
            {showTapCharacterContent && tapCharacterContent}
          </div>
        </PromptContainerGlobal>
      )} 
      
      {!isGlobalVersion() && showTapContainer && (
        <PromptContainer moveUp={showTapCharacterContent} >
          {showTapCanvasContent && tapCanvasContent}
          {showTapCharacterContent && tapCharacterContent}
        </PromptContainer>
      )}
      
    </React.Fragment>
  );
}
