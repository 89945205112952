// https://github.com/8thwall/web/blob/master/xrextras/src/almosttheremodule/almost-there-module.html

const html = `<div id="almostthereContainer" class="absolute-fill">
  <!--Not on mobile -->
  <div class="logo"></div>
  
  <div id="error_msg_device" class="hidden">
    <div class="background"></div>
    
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-20">
        <div class="almostThereLeftColumn">
            <div class="almostThereOhNo">Oh no!</div>
            <div class="almostThereNotSupported">The AR experience is not supported on this device.</div>
            <div class="scanTheCode">Scan the QR code on the right to access the experience on your phone or tablet.</div>
        </div>
        <div class="almostThereMiddleColumn">
        </div>
        <div class="almostThereRightColumn">
          <div id="qrcode"></div>
        </div>
<!--        <br />-->
          
<!--        <div class="desktop-message">-->
<!--          <span>-->
<!--            To view, open camera on smartphone and scan code-->
<!--          </span>-->
<!--        </div>-->
<!--        <div class="desktop-hint">-->
<!--          <span style="font-size:15pt;line-height:20pt;letter-spacing:-.21;">-->
<!--            or visit <br /><span class="desktop-home-link"></span><br />-->
<!--            on a smartphone or tablet.-->
<!--          </span>-->
<!--        </div>-->
<!--        <img class="foreground-image poweredby-img desktop" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg">-->
      </div>
    </div>
  </div>

  <!--iOS webview, reachable from button press -->
  <div id="error_msg_open_in_safari" class="hidden absolute-fill">
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-5">
        <span id="error_text_header_top" class="hidden open-header-top">
          <h2>Open in Safari<br /> to view AR</h2>
        </span>
        <span id="error_text_header_bottom" class="hidden open-header-bottom">
          <h2>Open in Safari<br /> to view AR</h2>
        </span>
        <img class="app-header-img">
        <img class="foreground-image poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg">
        <br />
        <img id="top_corner_open_safari" src="//cdn.8thwall.com/web/img/almostthere/v2/xtra-arrow.svg"
          class="foreground-image arrow-top-corner hidden" />
        <img id="top_close_open_safari" src="//cdn.8thwall.com/web/img/almostthere/v2/xtra-arrow.svg"
          class="foreground-image arrow-top-close hidden" />
        <img id="bottom_corner_open_safari" src="//cdn.8thwall.com/web/img/almostthere/v2/xtra-arrow.svg"
          class="foreground-image arrow-bottom-corner hidden" />
        <img id="bottom_close_open_safari" src="//cdn.8thwall.com/web/img/almostthere/v2/xtra-arrow.svg"
          class="foreground-image arrow-bottom-close hidden" />
      </div>
    </div>
  </div>

  <!--iOS webview, requires copy/paste of link -->
  <div id="error_unknown_webview" class="hidden">
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-5">
        <span id="error_text_header_unknown" class="open-header-unknown">
          <h2>Open in Safari<br /> to view AR</h2>
        </span>
        <img id="app_img" class="app-header-img unknown">
        <br />
        <span id="app_link" class="desktop-home-link mobile"></span>
        <button id="error_copy_link_btn" class="copy-link-btn">Copy Link</button>
        <img class="foreground-image poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg">
      </div>
    </div>
  </div>

  <!--Missing Web Assembly, or iOS 11.2 (which has a WebAssembly regression)-->
  <div id="error_msg_web_assembly_ios" class="hidden">
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-5">
        <p><img class="foreground-image safari-hero-image" src="//cdn.8thwall.com/web/img/almostthere/v2/safari-fallback.png"></p>
        <div class="error-text-header">You're almost there!</div>
        <div class="error-text-detail">
          To view this experience, please update to a newer version of iOS.
        </div>
      </div>
    </div>
  </div>
  <div id="error_msg_web_assembly_android" class="hidden">
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-5">
        <p><img src="//cdn.8thwall.com/web/img/almostthere/v1/google-chrome.png"></p>
        <div class="error-text-header">You're almost there!</div>
        <div class="error-text-detail">
          Browser doesn't support WebAssembly. Please update your browser.
        </div>
      </div>
    </div>
  </div>

  <!--Android unsupported browser -->
  <div id="error_msg_android_almost_there" class="hidden">
    <div class="error-text-outer-container">
      <div class="error-text-container error-margin-top-5">
        <p><img height="100px" src="//cdn.8thwall.com/web/img/almostthere/v1/google-chrome.png"></p>
        <div class="error-text-header">You're almost there!</div>
        <div class="error-text-detail">
          To view this experience on your Android device, please open in Google Chrome or your
          native browser.
        </div>
        <br />
        <p><span class="desktop-home-link"></span></p>
        <div id="android_copy_hint" class="error-text-hint">Open your browser and paste.</div>
      </div>
    </div>
  </div>
</div>
`;

export default html;
