import { configure, getConfig } from './capture-config';
import { unstable_batchedUpdates } from 'react-dom';
import { useUIStore } from '../../../services/uiService';

const IOS_DOWNLOAD_LOCATION =
  'shareddocuments:///private/var/mobile/Library/Mobile Documents/com~apple~CloudDocs/Downloads/';

const getFileNamePrefix = () => getConfig().fileNamePrefix || 'my-festive-friend-';

const clickAnchor = properties => {
  const anchor = document.createElement('a');
  Object.assign(anchor, properties);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

let currentBlob = null;
let currentUrl = null;
let currentFilename = null;
let previewInteractableTimeout = null;
let visibleObjectUrl = null;
let currentDownloadUrl = null;

let previewContainer;
let imagePreview;
let finalizeProgressBar;

const clearState = () => {
  currentBlob = null;
  if (currentUrl) {
    URL.revokeObjectURL(currentUrl);
  }
  if (visibleObjectUrl) {
    URL.revokeObjectURL(visibleObjectUrl);
  }
  clearTimeout(previewInteractableTimeout);
  previewContainer.style.removeProperty('pointer-events');
  finalizeProgressBar.value = 0;
  currentUrl = null;
  currentDownloadUrl = null;
  visibleObjectUrl = null;
  previewContainer.classList.remove('fade-in');
  previewContainer.classList.remove('image-preview');
  previewContainer.classList.remove('downloaded');
  previewContainer.classList.remove('finalize-waiting');
};

const closePreview = () => {
  unstable_batchedUpdates(() => {
    useUIStore.getState().setPreviewPhoto(false);
    useUIStore.getState().setBlurBackground(false);
  });
  clearState();
  imagePreview.removeAttribute('src');
  window.dispatchEvent(new CustomEvent('mediarecorder-previewclosed'));
};

const downloadFile = () => {
  clickAnchor({
    href: currentDownloadUrl || currentUrl,
    download: currentFilename,
  });

  previewContainer.classList.add('downloaded');
};

const openIosDownload = () => {
  clickAnchor({
    href: IOS_DOWNLOAD_LOCATION + currentFilename,
    target: '_blank',
  });
};

const share = () => {
  const fileToInclude = new File([currentBlob], currentFilename, {
    type: 'image/jpeg',
    lastModified: Date.now(),
  });

  const filesArray = [fileToInclude]
  if(navigator.canShare && navigator.canShare({ files: filesArray })) {
    const shareObject = { files: filesArray };

    navigator.share(shareObject);
  } else {
    downloadFile();
  }
};

const getTimestamp = () => {
  const now = new Date();
  return [now.getHours(), now.getMinutes(), now.getSeconds()].map(n => n.toString().padStart(2, '0')).join('');
};

const showPreview = () => {
  unstable_batchedUpdates(() => {
    useUIStore.getState().setPreviewPhoto(true);
    useUIStore.getState().setBlurBackground(true);
  });

  previewContainer.classList.add('fade-in');
  window.dispatchEvent(new CustomEvent('mediarecorder-previewopened'));
  // This prevents a click on the capture button from also causing a click on the download button
  clearTimeout(previewInteractableTimeout);
  previewInteractableTimeout = setTimeout(() => {
    previewContainer.style.pointerEvents = 'auto';
  }, 100);
};

const showImagePreview = ({ blob }) => {
  clearState();
  currentBlob = blob;
  currentUrl = URL.createObjectURL(blob);
  currentFilename = `${getFileNamePrefix()}${getTimestamp()}.jpg`;
  // previewIsImage = true;
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    imagePreview.src = reader.result;
    previewContainer.classList.add('image-preview');
    showPreview();
  };
};

const showImageHandler = event => showImagePreview(event.detail);

const progressHandler = event => {
  finalizeProgressBar.value = event.detail.progress;
  finalizeProgressBar.max = event.detail.total;
};

const initMediaPreview = (options = {}) => {
  previewContainer = document.getElementById('previewContainer');
  imagePreview = document.getElementById('imagePreview');
  finalizeProgressBar = document.getElementById('finalizeProgressBar');

  const downloadButton = document.getElementById('downloadButton');

  const tmpFile = new File([new Blob()], 'tmp.mp4', {
    type: 'video/mp4',
    lastModified: Date.now(),
  });
  const shareTestObj = {
    files: [tmpFile],
  };

  document.getElementById('finalizeText').textContent = options.finalizeText || 'Preparing...';

  window.addEventListener('mediarecorder-photocomplete', showImageHandler);
  window.addEventListener('mediarecorder-finalizeprogress', progressHandler);

  document.getElementById('closePreviewButton').addEventListener('click', closePreview);
  if (document.getElementById('downloadButton')) {
    downloadButton.addEventListener('click', share);
  }

  // Initialize with default configuration
  configure();
};

const removeMediaPreview = () => {
  previewContainer.parentNode.removeChild(previewContainer);
  clearState();
  currentFilename = null;
  previewContainer = null;
  imagePreview = null;
  finalizeProgressBar = null;
  window.removeEventListener('mediarecorder-photocomplete', showImageHandler);
  window.removeEventListener('mediarecorder-finalizeprogress', progressHandler);
};

export { initMediaPreview, removeMediaPreview };
