import create from '../../utils/zustand/create';

export const [useModelStore, modelApi] = create(module, (set, get) => ({
  character: 'snowy',
  default: 'snowy',
  characterIsSet: false,

  models: [
    {
      name: 'snowy',
      uppercaseName: 'Snowy',
      src: '/3d/snowy_06.glb',
      offset: [0, 0, 0],
      introScale: 0.6,
      component: (nodes, materials, isShown, group) => {
        return (
          <group ref={group} dispose={null}>
            <group name="root">
              <primitive object={nodes.root} />
              <skinnedMesh
                castShadow={isShown}
                geometry={nodes.scarf_limbs.geometry}
                material={nodes.scarf_limbs.material}
                skeleton={nodes.scarf_limbs.skeleton}
              />
              <skinnedMesh
                name="Body"
                castShadow={isShown}
                geometry={nodes.Body.geometry}
                material={nodes.Body.material}
                skeleton={nodes.Body.skeleton}
                morphTargetDictionary={nodes.Body.morphTargetDictionary}
                morphTargetInfluences={nodes.Body.morphTargetInfluences}
              />
            </group>
          </group>
        );
      },
    },
    {
      name: 'bear',
      uppercaseName: 'Bear',
      src: '/3d/bear_08.glb',
      offset: [0, 0, 0],
      introScale: 0.6,
      component: (nodes, materials, isShown, group) => {
        return (
          <group ref={group} dispose={null}>
            <group name="root">
              <primitive object={nodes.root} />
              <skinnedMesh
                name="body"
                castShadow={isShown}
                geometry={nodes.body.geometry}
                material={materials.mat_bear}
                skeleton={nodes.body.skeleton}
                morphTargetDictionary={nodes.body.morphTargetDictionary}
                morphTargetInfluences={nodes.body.morphTargetInfluences}
              />
            </group>
          </group>
        );
      },
    },
    {
      name: 'penguin',
      uppercaseName: 'Penguin',
      src: '/3d/penguin_06.glb',
      offset: [0, 0, 0],
      introScale: 0.6,
      component: (nodes, materials, isShown, group) => {
        return (
          <group ref={group} dispose={null}>
            <group name="root">
              <primitive object={nodes.root} />
              <skinnedMesh
                name="body"
                castShadow={isShown}
                geometry={nodes.body.geometry}
                material={materials.mat_penguin}
                skeleton={nodes.body.skeleton}
                morphTargetDictionary={nodes.body.morphTargetDictionary}
                morphTargetInfluences={nodes.body.morphTargetInfluences}
              />
              <skinnedMesh
                name="trumpet"
                castShadow={isShown}
                geometry={nodes.trumpet.geometry}
                material={materials.PartyHorn}
                skeleton={nodes.trumpet.skeleton}
                morphTargetDictionary={nodes.trumpet.morphTargetDictionary}
                morphTargetInfluences={nodes.trumpet.morphTargetInfluences}
              />
            </group>
          </group>
        );
      },
    },
    {
      name: 'rabbit',
      uppercaseName: 'Rabbit',
      src: '/3d/rabbit_06.glb',
      offset: [-0.5, 0, 0],
      introScale: 0.6,
      component: (nodes, materials, isShown, group) => {
        return (
          <group ref={group} dispose={null}>
            <group name="root">
              <primitive object={nodes.root} />
              <skinnedMesh
                castShadow={isShown}
                geometry={nodes.OUT_snowball_mesh.geometry}
                material={materials.mat_snowball}
                skeleton={nodes.OUT_snowball_mesh.skeleton}
              />
              <skinnedMesh
                name="body_low001"
                castShadow={isShown}
                geometry={nodes.body_low001.geometry}
                material={materials.mat_rabbit}
                skeleton={nodes.body_low001.skeleton}
                morphTargetDictionary={nodes.body_low001.morphTargetDictionary}
                morphTargetInfluences={nodes.body_low001.morphTargetInfluences}
              />
              <skinnedMesh
                name="body_low001_1"
                castShadow={isShown}
                geometry={nodes.body_low001_1.geometry}
                material={materials.mat_whiskers}
                skeleton={nodes.body_low001_1.skeleton}
                morphTargetDictionary={nodes.body_low001_1.morphTargetDictionary}
                morphTargetInfluences={nodes.body_low001_1.morphTargetInfluences}
              />
            </group>
          </group>
        );
      },
    },
    {
      name: 'ginger',
      uppercaseName: 'Ginger',
      src: '/3d/ginger_06.glb',
      offset: [-0.5, 0, 0],
      introScale: 0.6,
      component: (nodes, materials, isShown, group) => {
        return (
          <group ref={group} dispose={null}>
            <group name="root">
              <primitive object={nodes.root} castShadow={isShown} />
              <skinnedMesh
                castShadow={isShown}
                geometry={nodes.Beans.geometry}
                material={materials.mat_bean}
                skeleton={nodes.Beans.skeleton}
              />
              <skinnedMesh
                name="body"
                castShadow={isShown}
                geometry={nodes.body.geometry}
                material={materials.mat_ginger}
                skeleton={nodes.body.skeleton}
                morphTargetDictionary={nodes.body.morphTargetDictionary}
                morphTargetInfluences={nodes.body.morphTargetInfluences}
              />
            </group>
          </group>
        );
      },
    },
  ],

  setCharacter: character => {
    if (get().models.find(m => m.name === character)) {
      set({ character, characterIsSet: true });
    } else {
      set({ character: get().default, characterIsSet: true });
    }
  },

  getCharacter: () => {
    const { models, character } = get();
    return models.find(m => m.name === character);
  },

  checkCharacter: char => {
    const { models } = get();
    return !!models.find(m => m.name === char);
  },


  _getBuildTimeOverrideCharacter: () => {
    const { checkCharacter } = get();
    if("__env__" in window && "REACT_APP_CHARACTER" in window.__env__) {
      const character = window.__env__.REACT_APP_CHARACTER;
      if(checkCharacter(character)) {
        return character;
      }
    }
    return "";
  },

  findSelectedCharacter: (params) => {
    const { character: fallbackCharacter, checkCharacter, _getBuildTimeOverrideCharacter } = get();
    const urlBasedCharacter = params && checkCharacter(params.character) ? params.character : false;
    const buildTimeCharacter = _getBuildTimeOverrideCharacter();

    return buildTimeCharacter || urlBasedCharacter || fallbackCharacter;
  }
}));
