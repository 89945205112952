import { createGlobalStyle } from 'styled-components';
import { theme } from '../../../../globalStyles';

export const AlmostThereStyle = createGlobalStyle`
#almostthereContainer{
  
  --max-width: 1440px;
  --font-size: calc(min(100vw, var(--max-width)) * 0.00069444444);

  z-index: 10;
  background-color:${theme.colors.costaOffWhite};
  
  #error_msg_device {
      position: relative;
  
      .background {
          background-image: url('/fallback/bear.png');
  
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
      }
  
      max-width: var(--max-width);
      margin: 0 auto;
      
      height: 100%;
  
      
      .error-text-outer-container {
          height: 100%;
      }
      
      .error-text-container {
          height: 100%;
      
          display: flex;
          flex-direction: row;
          align-items: center;
      
          color: ${theme.colors.costaRed};
        
        .almostThereLeftColumn {
            width: 33.472222222%;
            margin-left: 9.375%;
            flex-shrink: 0;
            flex-grow: 0;
            
            .almostThereOhNo {
                font-family: BrandonGrotesque-Bold;
                font-size: calc(var(--font-size) * 120);
                line-height: calc(var(--font-size) * 100);
                margin-bottom: calc(var(--font-size) * 30);
            }
            .almostThereNotSupported {
                max-width: calc(var(--font-size) * 370);
            
                font-family: Gotham-Bold;
                font-size: calc(var(--font-size) * 28);
                line-height: calc(var(--font-size) * 34);
                margin-bottom: calc(var(--font-size) * 20);
            }
            .scanTheCode {
                max-width: calc(var(--font-size) * 370);
            
                font-family: ${theme.fonts.gothamMedium};
                font-size: calc(var(--font-size) * 14);
                line-height: calc(var(--font-size) * 22);
            }
        }
        
        .almostThereMiddleColumn {
            width: 15.625%;
            content: "";
            flex-shrink: 1;
            flex-grow: 1;
        }
        
        .almostThereRightColumn {
            width: 30.416666666%;
            margin-right: 11.111111111%;
            flex-shrink: 0;
            flex-grow: 0;
        
            #qrcode {
              background: #fff;
                padding: calc(var(--font-size) * 15);
                width: 100%;
                border-radius: 7.305936073%;
            
                filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
                overflow: hidden;
            }
        }
      }
    }
    
    &.character-bear #error_msg_device .background {
        z-index: 1;
    }
    &.character-snowy #error_msg_device .background {
        background-image: url('/fallback/snowy.png');
    }
    &.character-ginger #error_msg_device .background {
        background-image: url('/fallback/ginger.png');
        z-index: 1;
    }
    &.character-penguin #error_msg_device .background {
        background-image: url('/fallback/penguin.png');
    }
    &.character-rabbit #error_msg_device .background {
        background-image: url('/fallback/rabbit.png');
    }
    
    .logo {
      position: absolute;
      width: 124px;
      height: 47px;
      top: 59px;
      left: calc(50% - 62px);
    }

}`;
