import { BtnArrowRed } from '../../utils/svg';
import styled from 'styled-components';

export const SliderItemTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 3vh;

  img {
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 100%;
    max-height:100%;
  }
`
export const SliderItemImageWrapper = styled.div`
  width: 100%;
  height: 65%;

  img {
    width: 100%;
    object-fit: contain;
    object-position: bottom;
    max-height:100%;
    height: 100%;
  }
`

export const SliderItemWrapper = styled.div`
  padding: 10px 0;
  margin-bottom: 7px; 
`
export const SliderItem = styled.div`
  background: transparent;
  height: 100%;
  background-image: url(/share/share_bg.png);
  background-size: cover;
  border-radius: 25px;
  box-shadow: 0px 4px 10px 5px rgba(0,0,0,0.10);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
`;

export const SliderContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 1.5em; 
  padding-bottom: 1em; 
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  flex-direction: column;

  .center {
    margin: 0 -80%;
    display: block;
    overflow: hidden;
    flex-grow: 1;
    box-sizing: border-box;
  }

  .slick-list {
    padding: 0;
    height: 100%;
  }

  .slick-track, .slick-slider, .slick-slide {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .slick-slide {
    transform: scale(0.85);
    transition: all 0.3s ease-in-out;
  }

  .slick-slide:not(.slick-current) {
    transition-delay: 0s;
  }

  .slick-current {
    transform: scale(1);
  }
`;

export const StyledButtonRed = styled(BtnArrowRed)`
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
`;

export const ActionBar = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 18px;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 16px;

  .hiddenDiv{
    
  }
`;