const vert = `precision highp float;
precision highp int;

#define PI 3.14159
#define TWOPI (2.0*PI)
#define HALFPI (PI*0.5)

attribute float id;
attribute float index;
attribute float side;
attribute float size;
attribute float starProbability;
attribute float rotateSpeed;

varying vec2 vUv;
varying float vId;
varying float vIndex;
varying vec4 vPosition;
varying float vStarProbability;
varying float vRotateSpeed;
varying float vFade;

uniform float uTime;
uniform float uCameraAngle;

uniform float uSpinDuration;
uniform float uContractDuration;
uniform float uExplodeDuration;

uniform float uFadeInDuration;
uniform float uFadeOutDuration;
uniform float uFadeInIdOffset;

// float sineInOut(float t) {
//   return -0.5 * (cos(PI * t) - 1.0);
// }

float sineIn(float t) {
  return sin((t - 1.0) * HALFPI) + 1.0;
}

float sineOut(float t) {
  return sin(t * HALFPI);
}

mat2 rotation(float angle) {
    vec2 t = sin(vec2(angle, angle + HALFPI));
    return mat2(t.y,t.x,-t.x,t.y);
}

mat4 rotation3d(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;

  return mat4(
    c * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
    0.0,                                0.0,                                0.0,                                1.0
  );
}

vec3 rand(float num) {
    return fract(vec3(12.9898,78.233,54.23526) * sin(10000.0 * num));
}

vec3 spin(float time){
    float spins = 3.0 + time * 0.1;
    float r = 0.25 + (1.0-id) * 2.0;
    float y = id * 2.5;

    float angle = id * spins * TWOPI + (side > 0.5 ? 0.0 : PI);
    float speed = fract(id * 177.723) * time * 0.5;
    mat2 rot = rotation(angle + speed);

    vec3 particlePos = vec3(0.0);
    particlePos.y = y;
    particlePos.xz = rot * vec2(r, 0);

    vec3 drop = vec3(0);
    drop.xz = rot * vec2(1, 0) * clamp(time, 0.0, 1.0) * 0.5;

    float offsetFactor = (1.0 - id);
    vec3 offset = rand(id) * (offsetFactor * offsetFactor * 0.2 + 0.15);

    vec3 worldPos = particlePos + offset + drop;
    return worldPos;
}

vec3 scaleXZ(vec3 pos, float f){
  return pos * vec3(f, 1.0, f);
}


vec3 makeRound(vec3 pos, float f){
  vec3 roundPos = normalize(pos) * 2.0;
  return mix(pos, roundPos, f);
}

vec3 explode(vec3 pos, float t){
  t = 1.0 - (1.0 - t) * (1.0 - t) * (1.0 - t);
  float radius = 0.85;
  float f = 1.0 + t * radius;
 
  vec3 pivot = vec3(0, 1.0, 0);
  return (pos - pivot) * vec3(f) + pivot;
}

void main() {
    float time = uTime;

    vId = id;
    vIndex = index;
    // vUv = -1.0 + 2.0 * uv;
    vUv = uv;
    vStarProbability = starProbability;
    vRotateSpeed = rotateSpeed;


    //
    // STAGE 1
    // SPIN
    // 
    vec3 worldPos = spin(time);

    vec3 randomOffset = rand(id);
       
    //
    // STAGE 2
    // CONTRACT
    //  
    if(uSpinDuration < time) {
        float t = (time - uSpinDuration) / uContractDuration;
        t = clamp(t, 0.0, 1.0);
        //t = sineIn(t);
        worldPos = makeRound(worldPos, t);
        worldPos = scaleXZ(worldPos, 1.0 - t * 0.3);
        worldPos = (vec4(worldPos, 1.0) * rotation3d(vec3(0,1,0), -t)).xyz;

        randomOffset *= t * 0.2;
        worldPos += randomOffset;
    }
        
    //
    // STAGE 3
    // EXPLODE
    //  
    if(uSpinDuration + uContractDuration < time) {
        worldPos = spin(uSpinDuration + uContractDuration);
        worldPos = makeRound(worldPos, 1.0);
        worldPos = scaleXZ(worldPos, 0.7);
        worldPos = (vec4(worldPos, 1.0) * rotation3d(vec3(0,1,0), -1.0)).xyz;
        worldPos += randomOffset;
        
        float t = (time - (uSpinDuration + uContractDuration)) / uExplodeDuration;
        t = 1.0 - (1.0-t)*(1.0-t);
        // t = sineOut(t);
        worldPos = explode(worldPos, t);
        worldPos.y = max(worldPos.y, 0.0);
    }
    
    vec4 transformedWorldPos = modelMatrix * vec4(worldPos, 1.0);

    float durationBeforeFadeOut = uSpinDuration+uContractDuration+uExplodeDuration - uFadeOutDuration;
    float fadeIn = clamp((uTime - vId * uFadeInIdOffset) / uFadeInDuration, 0.0, 1.0);
    float fadeOut = 1.0 - clamp((uTime - durationBeforeFadeOut - vPosition.y * 0.3 + 0.3) / uFadeOutDuration + fract(id*777.0) * 3.0, 0.0, 1.0);
    vFade = fadeIn * fadeOut;

    vPosition = (viewMatrix * transformedWorldPos + vec4(position.xyz, 0.0) * size * vFade);

    gl_Position = projectionMatrix * vPosition;

}

`;
export default vert;
