import styled from 'styled-components';
import { theme } from '../../../globalStyles';
import { TapOnScreenIcon } from '../../../utils/svg';

export const PromptContainer = styled.div`
  box-sizing: content-box;
  padding: 12px 18px;
  position: fixed;
  display: flex;
  bottom: ${props => (props.moveUp ? '102px' : '36px')};
  left: 18px;
  right: 18px;
  border-radius: 16px;
  color: ${theme.colors.costaRed};
  font-size: 18px;
  line-height: 1.5em;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;
  backdrop-filter: blur(50px);
  align-items: center;
  height: 52px; 
`;

export const PromptContainerGlobal = styled.div`
  box-sizing: content-box;
  position: fixed;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  z-index: 10;
  align-items: center;
  pointer-events: none;   
  width: 100%;
  height: calc(100vh - 16px); 
  top: 0; 
  
  #tapBox {
    border-radius: 16px;
    backdrop-filter: blur(50px);
    background: rgba(255, 255, 255, 0.6);
    height: 112px; 
    width: 110px; 
  }
`;

export const TapText = styled.p`
  margin: 0;
  width: 100%;
  font-family: ${theme.fonts.gothamMedium};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledTapOnScreenIcon = styled(TapOnScreenIcon)`
  display: block;
  margin: -15px 0 -5px; 
  max-width: 60px; 
  height: auto;
`;
