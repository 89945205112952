import { theme } from '../../../globalStyles';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const ShareDialogItemContainer = styled(motion.div)`
  background-color: ${theme.colors.costaOffWhite};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  box-sizing: border-box;
  ${props =>
    props.hasCopy
      ? css`
          width: 80px;
          padding: 13.5px 13px 13.5px;
          border-radius: 16px;
        `
      : css`
          width: 64px;
          height: 64px;
          padding: 12px;
          border-radius: 50%;
        `}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShareDialogCopies = styled.p`
  font-family: ${theme.fonts.gothamMedium};
  color: ${theme.colors.costaRed};
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;
`;
