import { configure } from './capture-config';
import { drawWatermark } from './watermark';

let captureMode = 'standard';

let status = 'waiting';
let activeTimeout = null;
let isDown = false;

let isWaitingOnFinal = false;

let flashElement;

const clearDisplayState = () => {
  flashElement.classList.remove('flashing');

  clearTimeout(activeTimeout);
  isDown = false;
  status = 'waiting';
};

const clearState = () => {
  clearDisplayState();
  isWaitingOnFinal = false;
};

const previewOpened = () => {
  // Wait for preview to be shown before clearing the loading state
  clearDisplayState();
};

const previewClosed = () => {
  // If we're waiting on finalization of the media recording when the preview closes, we can't start
  // a new recording yet, so the record button must remain in a loading state.
  if (isWaitingOnFinal) {
    status = 'finalize-blocked';
  } else {
    clearState();
  }
};

const takeScreenshot = () => {
  status = 'flash';
  flashElement.classList.add('flashing');
  window.XR8.CanvasScreenshot.configure({ maxDimension: 1280, jpgCompression: 100 });
  window.XR8.CanvasScreenshot
  .takeScreenshot({
    onProcessFrame: ({ ctx }) => {
      drawWatermark(ctx);
    },
  })
    .then(data => {
      const bytes = atob(data);
      const buffer = new ArrayBuffer(bytes.length);
      const array = new Uint8Array(buffer);

      for (let i = 0; i < bytes.length; i++) {
        array[i] = bytes.charCodeAt(i);
      }

      const blob = new Blob([buffer], { type: 'image/jpeg' });

      clearState();
      window.dispatchEvent(new CustomEvent('mediarecorder-photocomplete', { detail: { blob } }));
    })
    .catch(() => {
      clearState();
    });
};

const goActive = () => {
  if (status !== 'waiting') {
    return;
  }

  status = 'active';
};

const cancelActive = () => {
  if (status !== 'active') {
    return;
  }

  takeScreenshot();
};

const down = e => {
  e.preventDefault();
  if (isDown) {
    return;
  }
  isDown = true;

  if (captureMode === 'photo') {
    takeScreenshot();
  } else if (status === 'waiting') {
    // Standard mode down starts active state
    goActive();
  }
};

const up = () => {
  if (!isDown) {
    return;
  }
  isDown = false;

  if (captureMode !== 'standard') {
    return;
  }

  if (status === 'active') {
    cancelActive();
  }
};

const initRecordButton = () => {
  window.XR8.CanvasScreenshot.configure({ maxDimension: 1280, jpgCompression: 100 });
  // eslint-disable-next-line no-undef
  window.XR8.addCameraPipelineModule(XR8.MediaRecorder.pipelineModule());

  flashElement = document.querySelector('#flashElement');

  const button = document.querySelector('#costa-recorder-button');

  button.addEventListener('touchstart', down);
  button.addEventListener('mousedown', down);

  window.addEventListener('mouseup', up);
  window.addEventListener('touchend', up);

  window.addEventListener('mediarecorder-previewclosed', previewClosed);
  window.addEventListener('mediarecorder-previewopened', previewOpened);

  // Initialize with default configuration
  configure();
};

const removeRecordButton = () => {
  window.XR8.removeCameraPipelineModule(window.XR8.MediaRecorder.pipelineModule().name);
  flashElement.parentNode.removeChild(flashElement);

  window.removeEventListener('mouseup', up);
  window.removeEventListener('touchend', up);

  window.removeEventListener('mediarecorder-previewclosed', previewClosed);
  window.removeEventListener('mediarecorder-previewopened', previewOpened);
  clearState();
  flashElement = null;
};

const setCaptureMode = mode => {
  switch (mode) {
    case 'photo':
    case 'fixed':
      captureMode = mode;
      break;
    default:
      captureMode = 'standard';
  }
};

export { initRecordButton, removeRecordButton, setCaptureMode };
