import React, { useEffect, useRef, useState } from 'react';
import { useVersionStore } from '../../../../services/versionService';
import {
  PreviewContainer,
  Overlay,
  PromptContainer,
  ActionsContainer,
  StyledBackBtnIcon,
  TextImagePreview,
  AuxSpace,
  StyledDownloadIconBtn,
} from './styles';

export default function MediaPreview() {
  const imgRef = useRef(null);
  const promptRef = useRef(null);
  const [width, setWidth] = useState(0);
  // @ts-ignore
  const {isGlobalVersion}  = useVersionStore(); 

  useEffect(() => {
    const origImgWidth = imgRef.current.naturalWidth;
    const origImgHeight = imgRef.current.naturalHeight;
    const aspect = origImgWidth / origImgHeight;
    setWidth(imgRef.current.clientHeight * aspect);
  });

  return (
    <React.Fragment>
      <PreviewContainer id="previewContainer">
        <PromptContainer ref={promptRef}>
          <Overlay isGlobal={isGlobalVersion()} width={width}>
            <img id="imagePreview" alt="" ref={imgRef} />

            {!isGlobalVersion() ? // if not global, show text
              <TextImagePreview>
                <p>Tap and hold your <span><br/></span>image to save</p>
              </TextImagePreview>
              : null  // if global, do not show
            }

          </Overlay>
        </PromptContainer>

        <ActionsContainer>
          <div className="finalize-container">
            <div id="finalizeText"></div>
            <progress id="finalizeProgressBar" value="0" max="100"></progress>
          </div>
          <StyledBackBtnIcon id="closePreviewButton" />
          <StyledDownloadIconBtn id="downloadButton" />
          <AuxSpace></AuxSpace>
          <div id="openSafariText" className="style-reset">
            Downloading is only available in Safari
          </div>
        </ActionsContainer>
      </PreviewContainer>
    </React.Fragment>
  );
}
