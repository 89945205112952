import { ShareDialogTitle } from '../styles';
import { theme } from '../../../globalStyles';
import styled from 'styled-components';

export const StyledSendAMessageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  z-index: 100001;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .logoRed {
    padding-top: 2rem;
    flex-shrink: 0;
    width: 124px;
    height: 47px;
  }

  ${ShareDialogTitle} {
    margin: 0;
    text-align: center;
    font-size: 28px;
    line-height: 40px;
  }
`;

export const SwipeCta = styled.div`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: ${theme.colors.costaRed};

  svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 19px;
    width: 88px;
    height: 86px;
  }
`;

export const SendAMessageText = styled.p`
  font-family: ${theme.fonts.gothamMedium};
  font-weight: 400; 
  font-size: 16px; 
  line-height: 24px; 
`;