import React, { useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import AnimatedModel from '../AnimatedModel/index';
import {
  AmbientLight,
  CameraHelper,
  Color,
  Mesh,
  PlaneGeometry,
  PointLight,
  DirectionalLight,
  ShadowMaterial,
} from 'three';
import { useXrStore } from '../../../services/xrService';
import ParticleAnimation from '../ParticleAnimation/index';
import SnowFlakes from '../SnowFlakes';

export default function ARScene() {
  const arSceneRef = useRef();
  const arSceneObjectsRef = useRef();
  // @ts-ignore
  const { camera, scene, position, lookAt, setSurface } = useXrStore();
  const { set } = useThree();

  const { dirLight, dirLight2, ambientLight, surface } = useMemo(() => {
    const dirLight = new PointLight(0xffffff, 0.4, 0, 1);
    const dirLight2 = new PointLight(0xffffff, 0.4, 0, 1);
    const ambientLight = new AmbientLight(0x404054, 0.3);

    const surface = new Mesh(
      new PlaneGeometry(1000, 1000),
      new ShadowMaterial({
        opacity: 0.35,
        polygonOffset: true,
        polygonOffsetFactor: 0.1,
        polygonOffsetUnits: 2,
      })
    );
    // const surface = new Mesh(
    //   new PlaneGeometry(10000, 10000),
    //   new ShadowMaterial({
    //     opacity: 0.35,
    //   })
    // );
    surface.name = 'surface';

    surface.rotateX(-Math.PI * 0.5);
    surface.receiveShadow = true;

    setSurface(surface);

    dirLight.position.set(0.0, 8.0, 0.0);
    dirLight.shadow.mapSize.width = 2048;
    dirLight.shadow.mapSize.height = 2048;
    dirLight.shadow.camera.near = 0.05;
    dirLight.shadow.camera.far = 1000;
    // dirLight.shadow.bias = 0.0002;
    dirLight.castShadow = true;

    dirLight2.position.set(0.0, 1.5, 9.0);
    dirLight2.castShadow = false;

    return { dirLight, dirLight2, ambientLight, surface };
  }, [setSurface]);

  useEffect(() => {
    if (camera) {
      set({ camera: camera });
    }
  }, [camera, set]);

  useEffect(() => {
    if (scene && arSceneRef.current) {
      scene.add(arSceneRef.current);
    }
  }, [scene]);

  // useEffect(() => {
  //   surface.position.set(...position);
  //   // surface.updateMatrixWorld();
  //   console.log(surface.position);
  // }, [surface, position]);

  useEffect(() => {
    if (arSceneObjectsRef)
      // @ts-ignore
      arSceneObjectsRef.current.lookAt(...lookAt);
    // @ts-ignore
    // arSceneObjectsRef.current.position.set(...position);
  }, [arSceneObjectsRef, lookAt, position, surface]);

  useFrame(() => null, 1);

  return (
    <group ref={arSceneRef}>
      <primitive object={ambientLight} />
      <hemisphereLight name="hemiLight" intensity={0.5} groundColor={new Color(0xf4f8ff)} position={[0.1, 1.0, 0.0]} />
      <group ref={arSceneObjectsRef} position={position}>
        <primitive object={dirLight} />
        <primitive object={dirLight2} />
        {/*<primitive object={helper} />*/}
        <primitive object={surface} />
        <ParticleAnimation />
        <AnimatedModel />
      </group>
      <SnowFlakes />
    </group>
  );
}
