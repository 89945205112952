import { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import IntroLogo from '../../components/IntroLogo';
import { AnimatePresence } from 'framer-motion';
import CookieBanner from '../../components/cookieBanner';
import { Helmet } from 'react-helmet';
import { CostaCoffeeLogoRed, BtnArrowRed } from '../../utils/svg';
import { useParams, Redirect } from 'react-router-dom';
import { useModelStore } from '../../services/modelService';
import { useUIStore } from '../../services/uiService';
import { useHistory } from 'react-router-dom';

import {
  IntroContainer,
  SvgAnimationContainer,
  Footer,
  LinksContainer,
  BtnShareIntroStyled,
  BtnArrowRedStyled,
  TitleTxtCont,
  TitleTxt,
  CharacterName
} from './styles';
import { AlmostThereFactory } from '../../components/threeAR/XR8Canvas/almost-there-module/almost-there-module';
import { useVersionStore } from '../../services/versionService';

const AlmostThere = AlmostThereFactory();

type URLParams = {
  character: string;
  lang: string;
};


declare let XR8: any;

function IntroPage() {

  // @ts-ignore
  const { checkCharacter, findSelectedCharacter } = useModelStore();

  // @ts-ignore
  const { lang } = useParams<URLParams>();
  // @ts-ignore
  const { setLoading:setVersionStoreLoading, isGlobalVersion,currentVersion,isBannerVisible,setCurrentVersion, loading:versionLoading } = useVersionStore();

  const history = useHistory();
  // @ts-ignore
  const usedCharacter = findSelectedCharacter(useParams<URLParams>());

  // @ts-ignore
  const { introAnimation,shareSiteAction } = useUIStore();
  let arURL = `/ar/${usedCharacter}`;

  if(lang){
    arURL =  `/ar/${usedCharacter}/${lang}`;
  }
  const [loading, setLoading] = useState(true);

  // We need to load the character animation data (which unfortunately is a
  // very big object with embedded pngs). We initially did load all the json
  // files, which resulted in super large build artefacts.
  // This is the fix now: We are dynamically requesting the JSON blob and let
  // webpack / dynamic imports handle the rest.
  const [characterAnimation, setCharacterAnimation] = useState();
  useEffect(() => {
    (async() => {
      if(!currentVersion || !usedCharacter || versionLoading)
        return;

      const characterAnimationFile = (currentVersion == "global")
        ? usedCharacter + "_global.json"
        : usedCharacter + ".json";

      setCharacterAnimation(await import(`../../lottie/${characterAnimationFile}`));
    })();
  }, [currentVersion, usedCharacter, versionLoading]);


  useEffect(() => {
    // In case we accidentally never trigger the loading
    // state of the version store, _or_ for some reasong
    // the version store stays in loading state for too
    // long, we just change it to be "done", which then
    // allows us to render the animation.
    // This code can unfortunately not live in the
    // versionStore itself, because it cannot monitor itself.
    setTimeout(() => {
      setVersionStoreLoading(false);
    }, 1000);
  }, []);


  const characterExists = usedCharacter ? checkCharacter(usedCharacter) : true;

  useEffect(() => {
    //NOTE: remove when go to prod
    setCurrentVersion(lang);
    document.body.classList.remove('body-red');
    if (characterExists && !XR8.XrDevice.isDeviceBrowserCompatible()) {
      AlmostThere.showAlmostThere(null, usedCharacter);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, introAnimation);
  });

  const goToArLink = () => {
    document.body.classList.add('body-red');
    history.push(arURL);
  }

  if (!characterExists) {
    return <Redirect to="/notFound" />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Make some new festive friends this season with Costa Coffee.</title>
        <meta name="title" content="Make some new festive friends this season with Costa Coffee." />
        <meta
          name="description"
          content="Meet Snowy, Bear, Ginger, Rabbit and Penguin and watch them bring the festive cheer, wherever you are!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://costacoffee.kube.dmdr.io/" />
        <meta property="og:title" content="Make some new festive friends this season with Costa Coffee." />
        <meta
          property="og:description"
          content="Meet Snowy, Bear, Ginger, Rabbit and Penguin and watch them bring the festive cheer, wherever you are!"
        />
        <meta property="og:image" content="https://costacoffee.kube.dmdr.io/costacoffeear.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://costacoffee.kube.dmdr.io/" />
        <meta property="twitter:title" content="Make some new festive friends this season with Costa Coffee." />
        <meta
          property="twitter:description"
          content="Meet Snowy, Bear, Ginger, Rabbit and Penguin and watch them bring the festive cheer, wherever you are!"
        />
        <meta property="twitter:image" content="https://costacoffee.kube.dmdr.io/costacoffeear.png" />
      </Helmet>
      <AnimatePresence>{loading && <IntroLogo animate={{ opacity: loading ? 1 : 0 }} />}</AnimatePresence>
      <IntroContainer>
        <CostaCoffeeLogoRed className="logoRed" />
        <SvgAnimationContainer className={isGlobalVersion()?"svgAnimationContGlobal":"svgAnimationCont"}>
          {!loading && characterAnimation && (
            <Lottie loop={false} animationData={characterAnimation} style={{ height: '100%' }} />
          )}
        </SvgAnimationContainer>
        <Footer>
          {!isGlobalVersion() && (
            <TitleTxtCont className="titleTxtCont">
              <TitleTxt className="titleTxtQuery">
                <CharacterName>{usedCharacter}</CharacterName>
                &nbsp;can’t wait <br/> to meet you!
              </TitleTxt>
            </TitleTxtCont>
          )}

          <LinksContainer className="footerCont">
            <BtnArrowRedStyled onClick={() => goToArLink()}/>
            <BtnShareIntroStyled onClick={() => shareSiteAction()} />
          </LinksContainer>
        </Footer>
        {!loading && <CookieBanner />}
      </IntroContainer>
    </>
  );
}

export default IntroPage;
