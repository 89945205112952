import styled from 'styled-components';
import { useUIStore } from '../../services/uiService';

const BackgroundContainer = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ease-in-out 0.2s;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  backdrop-filter: blur(10px);
  z-index: 10;
`;

export default function BlurredBackground() {
  // @ts-ignore
  const { blurBackground } = useUIStore();

  return <BackgroundContainer visible={blurBackground} />;
}
