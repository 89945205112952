import create from '../../utils/zustand/create';

export const [useLoadingStore, loadingApi] = create(module, (set, get) => ({
  loadingScreenIsVisible: false,
  rootNode: null,

  setLoadingScreenIsVisible: loadingScreenIsVisible => {
    set({ loadingScreenIsVisible });
  },

  setRootNode: rootNode => {
    set({ rootNode });
  },
}));
