import styled, {css} from 'styled-components'
import { theme } from "../../globalStyles";
import { motion } from "framer-motion"

interface IEvent {
  (event: Event): void
}

interface BtnParams {
  copy: string,
  primary?: boolean,
  clickEvent?: IEvent
}

const Btn: React.FC<BtnParams> = ({primary, copy, clickEvent}) => {
  return (
    <BtnContainer onClick={(e)=> clickEvent(e)} primary={primary}>
      {copy}
    </BtnContainer>
  );
}

const BtnContainer = styled(motion.div)`
  font-family: ${theme.fonts.gothamMedium};
  text-align: center;
  cursor: pointer;
  border-radius: 30px;
  padding: 0.6rem 0;
  margin: 0.5rem;
  margin-left: 0; 
  width: 11rem;
  background: transparent;
  color: ${theme.colors.costaOffWhite} ;
  border: 1px solid white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  ${props => props.primary && css`
    background: ${theme.colors.costaOffWhite} ;
    color: ${theme.colors.costaRed}; 
    margin-right: 0;
    margin-left: 0.5rem;
  `}
`
export default Btn;