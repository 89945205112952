import styled from 'styled-components';
import { theme } from '../../globalStyles';
import { Link } from 'react-router-dom';
import { 
  BtnShareIntro, 
  BtnArrowRed, 
  TapOnSurfaceGlobal, 
  TapOnCharacterGlobal
} from '../../utils/svg';
import MediaQuery from "react-responsive";

export const Footer = styled.div`
  height: auto;
  padding-bottom: 16px;
  width: 100%; 
`;

export const IntroContainer =styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  
  .titleTxtQueryCont{
    display:flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    margin-top: -4vh;
  }

  @media (max-height: 620px) {
    .svgAnimationCont{
      width: 90%; 
      margin-top: -5vh;
    }
    .svgAnimationContGlobal{
      width:90%;
    }
  }

  .logoRed {
    @media (max-height: 620px) {
      padding-top: 24px;
    }

    padding-top: 2rem;
    flex-shrink: 0;
    width: 124px;
    height: 47px;
  }
`;

export const TitleTxtCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TitleTxt = styled.h2`
  color: ${theme.colors.costaRed};
  font-family: ${theme.fonts.brandomGrotesqueBold};
  font-style: normal;
  font-weight: 900;
  font-size: 37px;
  line-height: 38px;
  margin: 0;
  letter-spacing: -1.5px;
  padding-bottom: 5vh;
  text-align:center;

  @media (max-height: 620px) {
    padding-bottom: 2vh;
  }
`;

export const CharacterName = styled.span`
  text-transform: capitalize;
`;

export const LinksContainer = styled.div`
  width: 100%;
  outline: 0;
  position: relative;

  a {
    outline: 0 !important;
  }

  a:hover,
  a:focus {
    background-color: transparent !important;
  }
`;

export const BtnArrowRedStyled = styled(BtnArrowRed)`
  background-color: transparent !important;
  display: block;
  height: 84px;
  margin: 0 auto;

  &::hover,
  &::active,
  &::focus {
    background-color: transparent !important;
    text-decoration: none;
    color: inherit;
  }
`;

export const BtnShareIntroStyled = styled(BtnShareIntro)`
  cursor: pointer;
  left: 65%;
  position: absolute;
  top: calc(50% - 2px);
  transform: translate(50%, -50%);
  height: 48px;
  //right: 48px; 
`;

export const SvgAnimationContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  margin-bottom: 9px;
  height: auto; 
  width: 100%;

  svg{
    width:100%;
  }

`;

export const TapOnSurfaceGlobalStyled = styled(TapOnSurfaceGlobal)`
  position: static;
  top: 50%; 
  left: 50%; 
`;

export const TapOnCharacterGlobalStyled = styled(TapOnCharacterGlobal)`
  position: static;
  top: 50%; 
  left: 50%;  
`;