import React, { useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import {
  AdditiveBlending,
  Color,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  PlaneGeometry,
  Vector3,
} from 'three';
import { useXrStore } from '../../../services/xrService';
import { fragmentShader, vertexShader } from './glsl/shader.glsl';

export const SECTOR_SIZE = 16;
const PARTICLE_COUNT = 256;
const INVERSE_FLAKE_SIZE = 6;

export default function SnowFlakes() {
  const materialRef = useRef();
  // @ts-ignore
  const camera = useXrStore(state => state.camera);

  const uniforms = useMemo(
    () => ({
      uCameraPosition: { value: new Vector3(0, 0, 0) },
      uSectorSize: { value: SECTOR_SIZE },
      uInverseFlakeSize: { value: INVERSE_FLAKE_SIZE },
      uTime: { value: 0 },
      uTotalTime: { value: 20 },
    }),
    []
  );

  const geom = useMemo(() => {
    const rnd = [];
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      rnd.push([Math.random() * 2 - 1, Math.random(), Math.random() * 2 - 1]);
    }

    const flakeOffsetArray = Float32Array.from(new Array(PARTICLE_COUNT * 3 * 9).fill(0));
    const flakeSectorArray = Float32Array.from(new Array(PARTICLE_COUNT * 2 * 9).fill(0));
    const offsetAttribute = new InstancedBufferAttribute(flakeOffsetArray, 3);
    const sectorAttribute = new InstancedBufferAttribute(flakeSectorArray, 2);

    let sectorIndex = 0;
    for (let x = -1; x <= 1; x++) {
      for (let z = -1; z <= 1; z++) {
        for (let p = 0; p < PARTICLE_COUNT; p++) {
          const index = sectorIndex * PARTICLE_COUNT + p;
          offsetAttribute.setXYZ(index, ...rnd[p]);
          sectorAttribute.setXY(index, x, z);
        }
        sectorIndex++;
      }
    }

    const geom = new InstancedBufferGeometry().copy(new PlaneGeometry(1, 1, 1, 1));

    geom.setAttribute('offset', offsetAttribute);
    geom.setAttribute('sector', sectorAttribute);

    return geom;
  }, []);

  const time = useRef(0);

  useFrame((context, dt) => {
    time.current = (time.current + dt) % uniforms.uTotalTime.value;
    uniforms.uTime.value = time.current;

    if (camera) {
      uniforms.uCameraPosition.value.copy(camera.position);
    }
    if (materialRef && materialRef.current) {
      // @ts-ignore
      materialRef.current.uniformsNeedUpdate = true;
    }
  });

  return (
    <instancedMesh args={[null, null, 9 * PARTICLE_COUNT]} geometry={geom}>
      <shaderMaterial
        ref={materialRef}
        attach="material"
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={uniforms}
        color={new Color(1, 1, 1)}
        transparent={true}
        blending={AdditiveBlending}
        depthTest={false}
        depthWrite={false}
      />
    </instancedMesh>
  );
}
