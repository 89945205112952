import { ShareDialogItemContainer, ShareDialogCopies } from './styles';
import { EmailShareIcon, ShareDialogIcon, GetAppShareDialog, GlobeShareIcon } from '../../../utils/svg';

interface ShareDialogItemI {
  copy?: string;
  svg?: string;
  onClick?: Function;
}

const ShareDialogItem: React.FC<ShareDialogItemI> = ({ copy, svg, onClick }) => {
  const shareIcons = {
    message: <EmailShareIcon />,
    share: <ShareDialogIcon />,
    getApp: <GetAppShareDialog />,
    globe: <GlobeShareIcon />,
  };

  return (
    <ShareDialogItemContainer onClick={onClick} hasCopy={copy}>
      {shareIcons[svg]}
      {copy && <ShareDialogCopies>{copy}</ShareDialogCopies>}
    </ShareDialogItemContainer>
  );
};

export default ShareDialogItem;
