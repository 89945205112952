const carouselDataCom = [
  {
    name: 'bear',
    src: '/share/bear-carousel-img.png',
    text: '/share/bear-carousel-text.svg',
    share: '/share/bear-share.png',
    url: 'https://bear.costacoffee-ar-experience.com/sharepage/bear.html',
  },
  {
    name: 'ginger',
    src: '/share/ginger-carousel-img.png',
    text: '/share/ginger-carousel-text.svg',
    share: '/share/ginger-share.png',
    url: 'https://ginger.costacoffee-ar-experience.com/sharepage/ginger.html',
  },
  {
    name: 'penguin',
    src: '/share/penguin-carousel-img.png',
    text: '/share/penguin-carousel-text.svg',
    share: '/share/penguin-share.png',
    url: 'https://penguin.costacoffee-ar-experience.com/sharepage/penguin.html',
  },
  {
    name: 'rabbit',
    src: '/share/rabbit-carousel-img.png',
    text: '/share/rabbit-carousel-text.svg',
    share: '/share/rabbit-share.png',
    url: 'https://rabbit.costacoffee-ar-experience.com/sharepage/rabbit.html',
  },
  {
    name: 'snowy',
    src: '/share/snowy-carousel-img.png',
    text: '/share/snowy-carousel-text.svg',
    share: '/share/snowy-share.jpg',
    url: 'https://snowy.costacoffee-ar-experience.com/sharepage/snowy.html',
  }
]


const carouselDataCn = [
  {
    name: 'bear',
    src: '/share/bear-carousel-img.png',
    text: '/share/bear-carousel-text.svg',
    share: '/share/bear-share.png',
    url: 'https://bear.costacoffee-ar-experience.costacoffee.cn/sharepage/bear.html',
  },
  {
    name: 'ginger',
    src: '/share/ginger-carousel-img.png',
    text: '/share/ginger-carousel-text.svg',
    share: '/share/ginger-share.png',
    url: 'https://ginger.costacoffee-ar-experience.costacoffee.cn/sharepage/ginger.html',
  },
  {
    name: 'penguin',
    src: '/share/penguin-carousel-img.png',
    text: '/share/penguin-carousel-text.svg',
    share: '/share/penguin-share.png',
    url: 'https://penguin.costacoffee-ar-experience.costacoffee.cn/sharepage/penguin.html',
  },
  {
    name: 'rabbit',
    src: '/share/rabbit-carousel-img.png',
    text: '/share/rabbit-carousel-text.svg',
    share: '/share/rabbit-share.png',
    url: 'https://rabbit.costacoffee-ar-experience.costacoffee.cn/sharepage/rabbit.html',
  },
  {
    name: 'snowy',
    src: '/share/snowy-carousel-img.png',
    text: '/share/snowy-carousel-text.svg',
    share: '/share/snowy-share.jpg',
    url: 'https://snowy.costacoffee-ar-experience.costacoffee.cn/sharepage/snowy.html',
  }
]

export default carouselDataCom