import styled from 'styled-components';
import { CostaCoffeeLogoWhite, ShareIconAR, ResetButton } from '../../../utils/svg';

export const CanvasContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

export const CostaCoffeeLogoWhiteElement = styled(CostaCoffeeLogoWhite)`
  position: fixed;
  top: 5%;
  right: 50%;
  z-index: 10;
  transform: translate(50%, -50%);
  display: ${ props => props.previewOn ? 'inline': 'none'  }
`;

export const ShareIconARElement = styled(ShareIconAR)`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
`;

export const StyledResetButton = styled(ResetButton)`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
`