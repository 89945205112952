import { theme } from '../../globalStyles';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ArrowDown } from '../../utils/svg';

export const ShareDialogContainer = styled(motion.div)`
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  text-align: center;
  width: calc(100% - 32px);
  z-index: 100000;
  height: ${props => props.isGlobal ? "158px" : "248px"}; 
  padding: 0 16px 12px;
`;

export const ArrowDownCont = styled.div`
  height: 30px; 
  width: 100%; 
  padding-top: 10px;
`;

export const ShareDialogTitle = styled.p`
  font-family: ${theme.fonts.brandomGrotesqueBold};
  color: ${theme.colors.costaRed};
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -1.5px;
  margin-top: 14px; 
  margin-bottom: 24px; 
`;

export const ShareDialogSpacer = styled.div`
  height: 16px;
`;

export const ShareDialogItemsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
