import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './globalStyles';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { AlmostThereStyle } from './components/threeAR/XR8Canvas/almost-there-module/almost-there-module.css';

// Extending the window interface, beacuse we add a property at compile time
// and want to make sure this is still type safe.
// Find the definition in the /public/index.html
declare global {
    interface Window {
      __env__: undefined | {
        REACT_APP_CHARACTER: string|undefined;
      }
  }
}

const resources = {
  en: {
    translation: {
      cookie_banner: 'To improve your visit, 1st and 3rd party cookies are used. See <anchor href="https://www.costa.co.uk/cookie-policy" target="_blank">Cookies</anchor> for more info. “I Decline” continues with necessary cookies. “I Accept” accepts all. ',
      device_warning: 'Please note, this experience may not be compatible with devices running outdated operation systems.',
      accept_btn: 'I accept',
      decline_btn: 'I decline',
      ok_btn: 'Ok'
    },
  },
  pl: {
    translation: {
      cookie_banner: 'Aby ulepszyć Twoją wizytę, używamy plików cookie stron pierwszych i trzecich. Zobacz Pliki <anchor href="https://www.costa.co.uk/cookie-policy" target="_blank">cookie</anchor>, aby uzyskać więcej informacji. Wybierając „Odrzucam” kontynuujesz z niezbędnymi plikami cookie. Wybierając „Akceptuję” akceptujesz wszystkie pliki cookie.',
      device_warning: 'Uwaga, ta aplikacja może nie działać prawidłowo na urządzeniach z nieaktualnym systemem operacyjnym',
      accept_btn: 'AKCEPTUJĘ',
      decline_btn: 'ODRZUCAM',
      ok_btn: 'OK'
    },
  },
  cn: {
    translation: {
      cookie_banner: '为了优化您的访问体验，我们和第三方服务商将手机您的<anchor href="https://www.costa.co.uk/cookie-policy" target="_blank">cookie</anchor>信息。查看更多关于cookie的信息。我“拒绝”仅提供必要的cookie信息。我“接受”同意所有的cookie收集。',
      device_warning: '请注意，此操作可能与较旧版本的操作系统不兼容',
      accept_btn: '接受',
      decline_btn: '拒绝',
      ok_btn: '接受'
    },
  },
  ar: {
    translation: {
      cookie_banner: 'لتحسين تصفحك، تم استخدام ملفات تعريف الارتباط الخاصة بالطرف الأول والثالث. اضغط تصفح ملفات تعريف الارتباط لمزيد من المعلومات. " أرفض" لاستمرار مع ملفات تعريف الارتباط. " أقبل" للقبول كل شيء.',
      device_warning: 'Please note, this experience may not be compatible with devices running outdated operation systems.',
      accept_btn: 'أرفض',
      decline_btn: 'أقبل',
      ok_btn: 'أرفض'
    },
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

declare const window: any;

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <AlmostThereStyle />
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
};


window.XR8 ? render() : window.addEventListener('xrloaded', render)
