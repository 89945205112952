import { Redirect, useParams, withRouter } from 'react-router-dom';
import React, { Suspense, useCallback, useEffect } from 'react';
import XRCanvas from '../components/threeAR/XR8Canvas';
import { useModelStore } from '../services/modelService';
import { useXrStore } from '../services/xrService';
import { useUIStore } from '../services/uiService';
import { ShareIconARElement, StyledResetButton } from '../components/threeAR/XR8Canvas/styles';
import ShareDialog from '../components/shareDialog';
import RecordButton from '../components/threeAR/MediaRecorder/RecordButton';
import MediaPreview from '../components/threeAR/MediaRecorder/MediaPreview';
import styled from 'styled-components';
import BlurredBackground from '../components/BluredBackground';
import TouchContainer from '../components/threeAR/TouchContainer';
import { useVersionStore } from '../services/versionService';

declare let XR8: any;



const ARExperience = withRouter(({ match }) => {
  
  // @ts-ignore
  const { setCharacter, checkCharacter, findSelectedCharacter } = useModelStore();
  const character = findSelectedCharacter(useParams<{character: string}>());

  const { lang } = useParams<{lang?:string}>();

  // @ts-ignore
  const { isPlaced, showShareDialog, place } = useXrStore();
  // @ts-ignore
  const { previewPhotoTaken, setBlurBackground } = useUIStore();
  // @ts-ignore
  const { setCurrentVersion } = useVersionStore();

  const characterExists = checkCharacter(character);

  useEffect(() => {
    setCharacter(character);
    document.body.classList.add('body-red');
    //NOTE: remove when go to prod
    if (lang) {
      setCurrentVersion(lang);
    }
  }, [character, setCharacter]);

  if (!characterExists) {
    return <Redirect to="/notFound" />;
  }

  const recenter = useCallback(() => {
    XR8.XrController.recenter();
    console.log('recenter');
    place([0, 0, 0]);
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={null}>
        <XRCanvas />
        <MediaPreview />
        <BlurredBackground />
        <ShareDialog />
        <ButtonContainer isVisible={!previewPhotoTaken}>
          <StyledResetButton isVisible={isPlaced} onClick={() => recenter()} />
          <RecordButton isVisible={isPlaced} />
          <ShareIconARElement
            isVisible={isPlaced}
            onClick={() => {
              showShareDialog(true);
              setBlurBackground(true);
            }}
          />
        </ButtonContainer>
      </Suspense>
    </React.Fragment>
  );
});

export default ARExperience;

const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 18px;
  gap: 16px;
  justify-content: center;
  z-index: 20;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
`;
