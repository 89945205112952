import React from 'react';
import { FlashElement, StyledRecordButton } from './styles';

export default function RecordButton({ isVisible }) {
  return (
    <React.Fragment>
      <StyledRecordButton isVisible={isVisible} />
      <FlashElement id="flashElement" className="flash-element" />
    </React.Fragment>
  );
}
