import styled from 'styled-components';
import { RecordBtnSVG } from '../../../../utils/svg';

export const StyledRecordButton = styled(RecordBtnSVG)`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
`;

export const FlashElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  opacity: 0;
  transition: 0.6s opacity;
  pointer-events: none;
  z-index: 40;

  &.flashing {
    transition: 0s opacity;
    opacity: 1;
    pointer-events: auto;
    z-index: 100;
  }
`;

export const RecorderContainer = styled.div`
  position: fixed;
  width: 20vmin;
  height: 20vmin;
  max-width: 10em;
  max-height: 10em;
  bottom: 3vmin;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  transition: 0.5s opacity;
`;

export const RecorderButton = styled.button`
  display: ${props => (props.visible ? 'block' : 'none')};
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  position: absolute;
  background: white;
  border-radius: 50%;
  color: transparent;
  background-color: #6d1f37;
  top: 0;
  left: 0;
  transform-origin: 50% 50%;
  transform: scale(0.6);
  transition: 0.3s border-radius, 0.3s transform;
  width: 100%;
  height: 100%;
`;
