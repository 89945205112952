import React from 'react';
import styled from 'styled-components';
import {
  ButtonContainer,
  CenterContainer,
  Home,
  NotFoundContainer,
  StyledNotFoundHomeButton,

} from './styles';
import { CostaCoffeeLogoRed } from '../../utils/svg';
import { theme } from '../../globalStyles';

const NotFound = () => {
  return (
    <NotFoundContainer>
      <CostaCoffeeLogoRed className="logoRed" />
      <CenterContainer>
        <StyledNotFound404Text>404!</StyledNotFound404Text>
        <StyledNotFoundOopsText>Ooops!</StyledNotFoundOopsText>
        <StyledNotFoundWrongText>Something went wrong!</StyledNotFoundWrongText>
      </CenterContainer>
      <ButtonContainer>
        <Home to="/">
          <StyledNotFoundHomeButton />
        </Home>
      </ButtonContainer>
    </NotFoundContainer>
  );
};

export const StyledNotFound404Text = styled.h1`
  font-family: ${theme.fonts.brandomGrotesqueBold};;
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  line-height: 120px;
  text-align: center;
  color: #6D1F37;
  margin: 0; 
  margin-bottom: 20px;
  letter-spacing: -1.5px; 
`;

export const StyledNotFoundOopsText = styled.p`
  font-family: ${theme.fonts.brandomGrotesqueBold};;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 57px;
  text-align: center;
  color: #6D1F37;
  margin: 0;
  margin-bottom: 5px;
  letter-spacing: -1.5px; 
`;

export const StyledNotFoundWrongText = styled.p`
  font-family: ${theme.fonts.gothamBold};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6D1F37;
  margin: 0;
`;

export default NotFound;
