import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ARExperience from './views/ARExperience';
import IntroPage from './views/Intro';
import ShareCarousel from './views/Share';
import { ResizeObserver } from '@juggle/resize-observer';
import NotFound from './views/NotFound';
import { useEffect } from 'react';
import { useVersionStore } from './services/versionService';

if (window.ResizeObserver === undefined) {
  window.ResizeObserver = ResizeObserver;
}

function App() {
  // @ts-ignore
  const { newUser,currentVersion,setCurrentCountry } = useVersionStore();

  useEffect(() => {
    if( newUser ) {
      setCurrentCountry();
    }
  },[currentVersion]);

  return (
    <>
      <Router>
        <Switch>
          <Route path="/notFound">
            <NotFound />
          </Route>
          <Route path="/ar/:character/:lang" children={<ARExperience />} />
          <Route path="/ar/:character" children={<ARExperience />} />
          <Route path="/share/:character" children={<ShareCarousel />} />
          <Route path="/:character/:lang"><IntroPage /></Route>
          <Route path="/:character"><IntroPage /></Route>
          <Route path="/"><IntroPage /></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
