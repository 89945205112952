import { useHistory } from 'react-router-dom';
import { useModelStore } from '../../../services/modelService';
import { ArrowRight, ArrowLeft, CostaCoffeeLogoRed, SendAMessageIcon } from '../../../utils/svg';
import { ShareDialogTitle } from '../styles';
import { StyledSendAMessageOverlay, SwipeCta, SendAMessageText } from './styles';
import { useUIStore } from '../../../services/uiService';
import { AuxSpace, StyledBackBtnIconNoCircle } from '../../threeAR/MediaRecorder/MediaPreview/styles';
import { ActionBar } from '../../../views/Share/styles';

const SendMessageOverlay = () => {
  // @ts-ignore
  const character = useModelStore(state => state.character);
  // @ts-ignore
  const { setShownSendMessageOverlay } = useUIStore()

  const history = useHistory();

  const handleCloseClick = () => {
    document.body.classList.remove('body-red');
    history.push(`/share/${character}`);
  };

  const handleGoBack = () => {
    setShownSendMessageOverlay(false);
  }

  return (
    <StyledSendAMessageOverlay >
      <CostaCoffeeLogoRed className="logoRed" />
      <ShareDialogTitle>Send a festive message<br />to family and friends!</ShareDialogTitle>
      <SwipeCta>
        <SendAMessageIcon />
        <SendAMessageText>Swipe through to pick<br />a character</SendAMessageText>
      </SwipeCta>
      <ActionBar>
        <StyledBackBtnIconNoCircle onClick={handleGoBack} />
        <ArrowRight className="nextBtnArrowRight" onClick={handleCloseClick} />
        <AuxSpace></AuxSpace>
      </ActionBar>
    </StyledSendAMessageOverlay>
  );
};

export default SendMessageOverlay;
