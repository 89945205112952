const frag = `varying vec2 vUv;
varying float vId;
varying float vIndex;
varying vec4 vPosition;
varying float vStarProbability;
varying float vRotateSpeed;
varying float vFade;

uniform float uTime;

uniform float uSpinDuration;
uniform float uContractDuration;
uniform float uExplodeDuration;

uniform sampler2D uStarTexture;

mat2 rotation(float angle) {
    vec2 t = sin(vec2(angle, angle + 3.14159*0.5));
    return mat2(t.y,t.x,-t.x,t.y);
}

void main() {
    vec2 starUv = (rotation(uTime * vRotateSpeed) * (vUv-0.5) * 2.0) + 0.5;
    vec4 star = texture2D(uStarTexture, starUv);
    // star.rgb *= vec3(1,0,0);
    vec2 uv2 = -1.0 + 2.0 * vUv;
    float dist = length(uv2) * 7.0;

    vec4 color = vStarProbability > 0.8 ? star : vec4(1, 1, 1, 1.0-dist);
    color.a *= vFade;

    gl_FragColor = color;
}
`;
export default frag;
