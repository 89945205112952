import create from '../../utils/zustand/create';

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const [useUIStore, uiApi] = create(module, (set, get) => ({
  introAnimation: 2400,
  blurBackground: false,
  acceptedCookieBanner: getLocalStorage('cookie-banner') || 'false',
  isAbleToPositionCharacter: false,
  shareItems: {
    bear: {
      src: '/share/bear.glb',
    },
    ginger: {
      src: '/share/ginger.glb',
    },
    penguin: {
      src: '/share/penguin.glb',
    },
    rabbit: {
      src: '/share/rabbit.glb',
    },
    snowy: {
      src: '/share/snowy.glb',
    },
  },
  previewPhotoTaken: false,
  shareData: {
    text: 'Make some new festive friends this season with Costa Coffee',
    //TODO: Use an empty string on the URL
    url: '',
  },
  isShownSendMessageOverlay: false,
  setIsAbleToPositionCharacter: able => {
    set({ isAbleToPositionCharacter: able });
  },
  setAcceptedCookiBanner: () => {
    set((state) => {
      setLocalStorage("cookie-banner", 'true');
    })
  },
  setBlurBackground: blurBackground => {
    set({ blurBackground });
  },
  setShownSendMessageOverlay: show => {
    set({ isShownSendMessageOverlay: show });
  },
  setPreviewPhoto: show => {
    set({ previewPhotoTaken: show });
  },
  shareSiteAction: () => {
    const { shareData } = get();

    if (navigator.canShare) {
      try {
        navigator
          .share(shareData)
          .then(() => console.log('Share was successful.'))
          .catch(error => console.log('Sharing failed', error));
      } catch (error) {
        alert(error);
      }
    }
  },
  appStoresRedirect: () => {
    if (window.XR8.XrDevice.deviceEstimate().os === 'iOS') {
      window.location.href = 'https://apps.apple.com/gb/app/costa-coffee-club/id578627826';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=uk.co.club.costa.costa&hl=en_GB&gl=US';
    }
  },
}));

