import styled from 'styled-components';
import { theme } from '../../../../globalStyles';
import { BackBtnIcon, ShareIconBtn, BackBtnIconNoCircle, DownloadBtnIcon } from '../../../../utils/svg';

export const Overlay = styled.div`
  border-radius: 16px;
  overflow: hidden;
  backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  width: ${props => props.width}px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px;

  @media (max-height: 714px) {
    margin-bottom: 0;
    padding-bottom: 0;
    margin: 8px 20px 15px;

  }    

  #imagePreview {
    border: 0;
    filter: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: ${props => (props.isGlobal)?"10px":"0"};
    border-radius: 10px;
    // display: block;
    // width: auto;
    // max-width: 100%;
    object-fit: contain;
  }
`;

export const TextImagePreview = styled.div`

  p {
    font-family: ${theme.fonts.gothamMedium};
    color: ${theme.colors.costaRed};
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-height: 714px){
    margin: 10px 0 5px;
  }
  
  span {
    display: none; 
  }
  
  @media (max-height: 714px) {
    
    p{
      margin: 0; 
    }

    padding: 12px 18px;  

    span{
      display: inline; 
    } 
  }

`;

export const PreviewContainer = styled.div`
  z-index: 30;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  flex-direction: column;
`;

export const PromptContainer = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 18px;
  gap: 16px;
  
  .finalize-container,
  #openSafariText,
  #actionButton {
    display: none;
  }
  `;

export const AuxSpace = styled.div`
  width: 68px;
`;
  
export const StyledBackBtnIconNoCircle = styled(BackBtnIconNoCircle)`
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px); 
  z-index: 100000; 
`;

export const StyledBackBtnIcon = styled(BackBtnIcon)`
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
  z-index: 100000; 
`;

export const StyledShareIconBtn = styled(ShareIconBtn)`
  width: clamp(68px, 12vw, 108px);
  height: clamp(68px, 12vw, 108px);
  z-index: 100000;
`;

export const StyledDownloadIconBtn = styled(DownloadBtnIcon)`
  z-index: 100000;
`;

