import { useMemo } from 'react';
import { TouchArea } from './styles';
import { useXrStore } from '../../../services/xrService';
import { useUIStore } from '../../../services/uiService';
import { Raycaster, Vector2 } from 'three';

export default function TouchContainer({ children }) {
  // @ts-ignore
  const { scene, camera, place, surface, isShown } = useXrStore();
  // @ts-ignore
  const { isAbleToPositionCharacter, setIsAbleToPositionCharacter} = useUIStore()

  const { raycaster } = useMemo(() => {
    if (!scene) return {};

    return {
      raycaster: new Raycaster(),
    };
  }, [scene]);

  const handleTouchStart = e => {
    if (e.touches.length > 1) {
      return;
    }

    // If the canvas is tapped with one finger and hits the "surface", spawn an object.
    const tapPosition = new Vector2();
    // calculate tap position in normalized device coordinates (-1 to +1) for both components.
    tapPosition.x = (e.touches[0].clientX / window.innerWidth) * 2 - 1;
    tapPosition.y = -(e.touches[0].clientY / window.innerHeight) * 2 + 1;

    // Update the picking ray with the camera and tap position.
    raycaster.setFromCamera(tapPosition, camera);
    // Raycast against the "surface" object.
    const intersects = raycaster.intersectObject(scene, true);

    if (intersects.length > 0 && intersects[0].object === surface && !isAbleToPositionCharacter) {
      //For the intro animation
      if (!isShown) {
        setIsAbleToPositionCharacter(true);
      }
      const pos = [intersects[0].point.x, 0, intersects[0].point.z];
      place(pos);
    }
  };

  const handleTouchMove = e => {
    // e.preventDefault();
  };

  return (
    <TouchArea onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      {children}
    </TouchArea>
  );
}
