export const vertexShader = `
#define CUSTOM_SHADER_NAME snowflake_particles

#define PI 3.14159
#define TWOPI (2.0*PI)
#define HALFPI (PI*0.5)

precision highp float;

attribute vec3 offset;
attribute vec2 sector;

uniform vec3 uCameraPosition;
uniform float uSectorSize;
uniform float uTime;
uniform float uTotalTime;

varying vec4 vPosition;
varying vec2 vUv;
varying vec2 vSector;
varying vec3 vFlakePos;
varying float vDistToCamera;

float roundfloat(float number){
  float absolute = abs(number);
  float fraction = absolute - floor(absolute);
  return sign(number) * (fraction > 0.5 ? ceil(absolute) : floor(absolute));
}

vec3 rround(vec3 number){
  return vec3(
    roundfloat(number.x),
    roundfloat(number.y),
    roundfloat(number.z)
  );
}


void main() {
  vSector = sector;
  vUv = uv;

  vec3 flakePos = offset;
  flakePos.y = mod(flakePos.y - uTime / uTotalTime, 1.0);
  flakePos.x += sin(uTime * 0.05 * TWOPI) * 0.3;
  flakePos.x += sin(uTime * 0.10 * TWOPI) * 0.1;
  vFlakePos = flakePos;
  
  flakePos.y *= 5.0;
  
  
  vec3 camPos = uCameraPosition;
  camPos.y = 0.0;
  
  vec3 sectorOffset = rround(camPos * vec3(1.0 / uSectorSize)) * vec3(uSectorSize) + uSectorSize * vec3(sector.x, 0, sector.y);

  vec3 worldPos = 0.5 * uSectorSize * flakePos + sectorOffset;

  vec4 transformedWorldPos = modelMatrix * vec4(worldPos, 1.0);
  
  vPosition = (viewMatrix * transformedWorldPos + vec4(position.xyz, 0.0));
  vDistToCamera = length(vec3(worldPos.x, 0.0, worldPos.z) - camPos);

  gl_Position = projectionMatrix * vPosition;
}
`;

export const fragmentShader = `
#define CUSTOM_SHADER_NAME snowflake_particles

uniform vec3 uCameraPosition;
uniform float uSectorSize;
uniform float uInverseFlakeSize;

precision highp float;

varying vec2 vUv;
varying vec2 vSector;
varying vec3 vFlakePos;
varying float vDistToCamera;

float circle(vec2 st, float r, float b) {
  vec2 d = st-vec2(0.5);
  return 1.0 - smoothstep(r-(r*b),r+(r*b),dot(d,d)*4.0);
}

void main() {
  float dist = 1.0 - min(vDistToCamera / uSectorSize, 1.0);

  float size = (1.0 - min(length(vUv * 2.0 - 1.0) * min(uInverseFlakeSize, 2.0 / (dist*dist*dist)), 1.0));
  
  float fadeIn  = 1.0 - (vFlakePos.y > 0.9 ? (vFlakePos.y - 0.9) / 0.1 : 0.0);
  float fadeOut = vFlakePos.y < 0.05 ? vFlakePos.y / 0.05 : 1.0;
  
  // vec3 color = vec3(0.5 + vSector.x * 0.5, 0.5 + vSector.y * 0.5, 0.0);
  // vec3 color = vec3(0.5 + vOffset * 0.5);
  vec3 color = vec3(1);

  gl_FragColor = vec4(color, size * fadeIn * fadeOut);
}
`;
