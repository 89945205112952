import { AnimatePresence } from 'framer-motion';
import { ShareDialogContainer, ShareDialogTitle, ShareDialogSpacer, ShareDialogItemsContainer, ArrowDownCont } from './styles';
import { useXrStore } from '../../services/xrService';
import { ArrowDown } from '../../utils/svg';
import ShareDialogItem from './shareDialogItem';
import { useState } from 'react';
import SendMessageOverlay from './sendMessageOverlay';
import { useUIStore } from '../../services/uiService';
import { useVersionStore } from '../../services/versionService';

const ShareDialog: React.FC = () => {
  // @ts-ignore
  const { shareDialogOpen, showShareDialog } = useXrStore();
  // @ts-ignore
  const { shareSiteAction, appStoresRedirect, isShownSendMessageOverlay, setShownSendMessageOverlay, setBlurBackground} = useUIStore();
  // @ts-ignore
  const isGlobalVersion = useVersionStore(state => state.isGlobalVersion());
  // @ts-ignore
  const { externalLink } = useVersionStore();

  const acceptClickEvent = () => {
    showShareDialog(false);
    setBlurBackground(false);
  };

  const goToShare = () => {
    setShownSendMessageOverlay(true);
  };

  const goToSite = () => {
    window.open(externalLink, '_blank');
  }

  const englishItems = [
    { icon: 'message', copy: 'Send a message', onClick: goToShare },
    { icon: 'share', copy: 'Share', onClick: shareSiteAction },
    { icon: 'getApp', copy: 'Get the app', onClick: appStoresRedirect },
  ];

  const globalItems = [
    { icon: 'share', onClick: shareSiteAction },
    { icon: 'globe', onClick: goToSite },
  ];

  return (
    <>
      <AnimatePresence>
        {shareDialogOpen && (
          <ShareDialogContainer
            isGlobal={isGlobalVersion}
            key="shareDialogContainer"
            initial={{
              bottom: -300,
              opacity: 0,
            }}
            animate={{
              bottom: 0,
              opacity: 1,
              transition: { duration: 0.5 },
            }}
            exit={{
              bottom: -300,
              opacity: 0,
              transition: { duration: 0.5 },
            }}
          >
            <ArrowDownCont>
              <ArrowDown onClick={() => acceptClickEvent()} />
            </ArrowDownCont>
            {!isGlobalVersion && <ShareDialogTitle>Share the festive fun</ShareDialogTitle>}
            {isGlobalVersion && <ShareDialogSpacer />}
            <ShareDialogItemsContainer>
              {!isGlobalVersion &&
                englishItems.map((item, i) => (
                  <ShareDialogItem key={i} svg={item.icon} copy={item.copy} onClick={item.onClick} />
                ))}
              {isGlobalVersion &&
                globalItems.map((item, i) => (
                  <ShareDialogItem key={i} svg={item.icon} onClick={item.onClick} />
                ))}
            </ShareDialogItemsContainer>
          </ShareDialogContainer>
        )}
        {isShownSendMessageOverlay && <SendMessageOverlay key="sendMessageOverlay" />}
      </AnimatePresence>
    </>
  );
};

export default ShareDialog;
