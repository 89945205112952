import create from 'zustand';
import i18n from "i18next";

type BannerLangs = "en" | "cn" | "pl" | "ar" | "none";
type Versions = "global" | "en";

type VersionStore = {
  loading: boolean,
  currentVersion: Versions,
  currentCountryCode: string,
  currentCountry: string,
  bannerLang: BannerLangs,
  isTracking: boolean,
  newUser: boolean,
  externalLink: string,
  countryList: {
    [key: string]: {
      version: Versions,
      bannerLang: BannerLangs,
      tracking: boolean,
      name: string,
      link: string
    }
  },
  isGlobalVersion: () => boolean,
  isBannerVisible: () => boolean,
  setCurrentCountry: () => void,
  setCurrentVersion: (countryCodeG:string) => void,
  setNewUser: () => void,
  setLoading: (loading:boolean) => void
}

export const useVersionStore = create<VersionStore>((set,get) => ({
  loading: true,
  currentVersion: 'en', // en / global
  currentCountryCode: 'uk',
  currentCountry: 'uk',
  bannerLang: 'en', 
  isTracking: false,
  newUser: true,
  externalLink: 'app',
  countryList: {
    'GB': {
      version: 'en',
      bannerLang: 'en',
      tracking: true,
      name: 'uk',
      link: 'app'
    },
    'IE': {
      version: 'en',
      bannerLang: 'en',
      tracking: true,
      name: 'ireland',
      link: 'app'
    },
    'IN': {
      version: 'en',
      bannerLang: 'none',
      tracking: false,
      name: 'india',
      link: 'https://www.instagram.com/costacoffeeindia/?hl=en'
    },
    'MY': {
      version: 'en',
      bannerLang: 'none',
      tracking: false,
      name: 'malaysia',
      link: 'https://www.instagram.com/costacoffeemalaysia/?hl=en'
    },
    'US': {
      version: 'en',
      bannerLang: 'none',
      tracking: true,
      name: 'usa',
      link: 'https://www.instagram.com/costacoffeeus/?hl=en'
    },
    'CN': {
      version: 'global',
      bannerLang: 'cn',
      tracking: true,
      name: 'china',
      link: 'http://aglc.cn/v-Hyzfs'
    },
    'MX': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'mexico',
      link: 'https://www.costacoffee.mx/en'
    },
    'ES': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'spain',
      link: 'https://www.instagram.com/costacoffeeesp/?hl=en'
    },
    'BG': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'bulgaria',
      link: 'https://www.instagram.com/costacoffee_bulgaria/?hl=en'
    },
    'CZ': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'czech republic',
      link: 'https://www.instagram.com/costacoffeecz/?hl=en'
    },
    'CY': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'cyprus',
      link: 'https://www.instagram.com/costacoffeecyprus/?hl=en'
    },
    'FR': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'france',
      link: 'https://www.instagram.com/costacoffee_fr/?hl=en'
    },
    'DE': {
      version: 'en',
      bannerLang: 'none',
      tracking: false,
      name: 'Germany',
      link: 'https://www.instagram.com/costacoffeede/?hl=en'
    },
    'HU': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'hungary',
      link: 'https://www.instagram.com/costahungary/?hl=en'
    },
    'MT': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'malta',
      link: 'https://www.instagram.com/costacoffeemalta/'
    },
    'PT': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'portugal',
      link: 'https://www.instagram.com/costacoffee/?hl=en'
    },
    'GI': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'gibraltar',
      link: 'https://www.facebook.com/CostaCoffeeGibraltar/'
    },
    'RU': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'russia',
      link: 'https://www.instagram.com/costa_coffee_russia/?hl=en'
    },
    'KZ': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'kazakhstan',
      link: 'https://www.instagram.com/costacoffee_kz/?hl=en'
    },
    'MO': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'morroco',
      link: 'https://www.instagram.com/costacoffee/?hl=en'
    },
    'AE': {
      version: 'global',
      bannerLang: 'ar',
      tracking: false,
      name: 'dubai',
      link: 'https://www.instagram.com/costacoffeeuae/?hl=en'
    },
    'KW': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'kuwait',
      link: 'https://www.instagram.com/costakuwait/?hl=en'
    },
    'SA': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'saudi arabia',
      link: 'https://www.instagram.com/costa.ksa/?hl=en'
    },
    'OM': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'oman',
      link: 'https://www.instagram.com/costacoffee/?hl=en'
    },
    'JO': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'jordan',
      link: 'https://www.instagram.com/costacoffeejor/?hl=en'
    },
    'QA': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'qatar',
      link: 'https://www.instagram.com/costa.qatar/?hl=en'
    },
    'TR': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'turkey',
      link: 'https://www.instagram.com/costacoffee/?hl=en'
    },
    'PL': {
      version: 'global',
      bannerLang: 'pl',
      tracking: false,
      name: 'poland',
      link: 'https://www.instagram.com/costacoffeepolska/?hl=en'
    },
    'PJ': {
      version: 'global',
      bannerLang: 'none',
      tracking: false,
      name: 'japan',
      link: 'https://www.instagram.com/costacoffeejp/?hl=en'
    },
  },
  isGlobalVersion: () => {
    const { currentVersion } = get();
    return currentVersion === 'global';
  },
  isBannerVisible: () => {
    const { bannerLang } = get();
    return bannerLang !== 'none';
  },
  setCurrentCountry: async () => {
    const { setCurrentVersion } = get();
    
    await fetch('https://extreme-ip-lookup.com/json/?key=qun6Bm5JIVnP9pDnusVZ')
      .then( res => res.json())
      .then(response => {
        setCurrentVersion(response.countryCode)
      })
      .catch((data) => {
          set({loading: false});
      })
  },
  setCurrentVersion: (countryCodeG) => {
    const countryCode = countryCodeG ? countryCodeG.toUpperCase() : countryCodeG;

    const { countryList } = get();

    if (countryList[countryCode]) {
      i18n.changeLanguage(countryList[countryCode].bannerLang)

      set({
        loading: false,
        currentCountry: countryList[countryCode].name,
        currentVersion: countryList[countryCode].version,
        bannerLang: countryList[countryCode].bannerLang, 
        isTracking: countryList[countryCode].tracking, 
        externalLink: countryList[countryCode].link,  
        currentCountryCode: countryCode, 
        newUser: false
      })
    }
  },
  setNewUser: () => {
    set({newUser: false})
  },
  setLoading: (loading:boolean) => {
    set({loading})
  }
}))